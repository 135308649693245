import Box from '@mui/material/Box';
import ResultsCarousel from './resultsCarousel/ResultsCarousel';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StoreChange from '../../components/popups/StoreChange';
import { useState } from 'react';

const CustomResults = ({ storeSelected, changeStore, type, headData, setFullyBuilt, setSpecsFull, setSpecsToSend, setCustomShowing, setStyleValues,  }) => {

const resetClick = () => {
  setFullyBuilt(false);
  setSpecsFull(false);
  setSpecsToSend({});
  setCustomShowing(false);
  setStyleValues({
    style: 3,
    darkBright: 3,
    dryResonant: 3
  });
}

const backToSoundStyle = () => {
  setCustomShowing(false);
}

//pre-selected snare and bass size
const [sizeSelected, setSizeSelected] = useState({
  snare: true,
  toms: false,
  bass: true
});

const [openResultsModal, setOpenResultsModal] = useState(false);
const handleResultsOpen = () => setOpenResultsModal(true);
const handleResultsClose = () => setOpenResultsModal(false);

  return(
    <Box sx={{ height: {xs: '625px', sm: 'auto', md: 'auto', lg: 'auto'}, maxWidth: {lg: '1100px', md: '850px'}, margin:'0 auto', position: 'relative', padding: {xs: '10px 0 0', sm: '45px 0 0', md: '40px 0 0', lg: '60px 0 0'} }}>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center', width: {xs: '93%', sm: '505px', md: '80%', lg:'75%'}, margin: '0 auto', maxWidth: {xs: '420px', sm: '500px', md: '1100px'}, minWidth: '360px' }}>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center', width: {xs: '80px', sm: '90px', md: '14%'} }}>
          <Box sx={{ margin: {xs: '0 3px 0 0', sm: '0'} }}>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={resetClick}
              color="primary"
              sx={{ padding: {xs: '8px 8px 8px 2px', sm: '8px 8px 12px'}, margin: {xs: '0 3px 0 0'} }}
            >
              <RestartAltIcon sx={{ fontSize: {xs: '28px', sm: '28px', md: '30px', lg: '32px'} }}/>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={backToSoundStyle}
              color="primary"
              sx={{ padding: {xs: '8px 3px 8px 2px', sm: '8px 8px 12px'}}}
            >
              <ArrowBackIcon sx={{ fontSize: {xs: '28px', sm: '28px', md: '30px', lg: '32px'} }}/>
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: {xs: 'none', md: 'flex'}, alignItems: 'center', width: {sm:'275px', md: '55%', lg: '51%'} }}>
          <Box sx={{ margin: '0 0 7px 10px'}}>
            <Typography variant="h4" component="h4" sx={{ color: '#ffffffb3', fontSize: {xs: '20px', sm: '24px', md: '26px', lg: '29px'}, margin: '0', display: {xs: 'none', md: 'inline'} }}>YOUR RECOMMENDATIONS</Typography>
            <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleResultsOpen} sx={{ padding: '5px 5px 10px' }}/>
          </Box>
        </Box>
        <Box sx={{ width: {xs: '255px', sm:'265px', md: '53%', lg: '49%'}, display: {xs: 'flex', md: 'none'}, flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center', margin: {xs: '0 0 8px', sm: '0', md: '0', lg: '0'}, padding: {xs: '0 10px 0 0', sm: '0 5px 0 0'} }}>
          <Box sx={{ width: {xs: '10px', sm: '20px'}, height: '10px' }}>
          </Box>
          <Box sx={{ margin: {xs: '0px', sm: '0 0 7px'} }}>
            <Typography variant="h4" component="h4" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '20px', md: '26px', lg: '29px'}, display: {xs: 'block', md: 'none'}, fontWeight: {xs: '600', sm: '500'} }}>YOUR</Typography>
            <Typography variant="h4" component="h4" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '20px', md: '26px', lg: '29px'}, display: {xs: 'inline', md: 'none'}, fontWeight: {xs: '600', sm: '500'} }}>RECOMMENDATIONS</Typography>
          </Box>
          <Box sx={{  }}>
            <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleResultsOpen} sx={{ padding: '5px 5px 5px 0', position: 'relative', fontSize: {xs: '17px', sm: '20px', md: '22px'}}}/>
          </Box>

        </Box>
        <Modal
          open={openResultsModal}
          onClose={handleResultsClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box className='modal-style' sx={{ backgroundColor: '#eceff1' }}>
          <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'} }}>
            <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500'}}>
              RECOMMENDATIONS
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: '20px', mb: '18px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
              These recommendations should give drummers a range of heads that can achieve the desired sound to the greatest extent possible, for that particular player and instrument.
              No heads can do everything, so we weigh the costs and benefits of each head when calculating outcomes.
            </Typography>

            <Typography id="modal-modal-description" sx={{ mb: '18px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
              Keep in mind that describing sound is somewhat subjective, so our dark-bright ratings may differ from other sites or manufacturers.
            </Typography>
            <Typography id="modal-modal-description" sx={{ mb: '18px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
              Also remember it can be advantageous to lean towards heads with slightly more resonance.
              It's fairly simple to reduce resonance externally, but unless a head comes with adjustable dampening, you can't add resonance to a head.
            </Typography>
            <Typography id="modal-modal-description" sx={{ fontStyle: 'italic', mb: '20px', color: '#131414', fontSize: {xs: '13px', sm: '14px', md: '14px' }, padding: {xs: '15px 0px 0px', sm: '20px 0px 0px', md: '25px 0px 0px'} }}>
            * DrumheadSelector may receive a small commission for sales generated through Amazon product links.  Of course, your price doesn't increase at all!
            Thanks so much for your support!
            </Typography>
          </Box>
        </Box>
        </Modal>

        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center', width: {xs: '80px', sm: '90px', md: '14%'} }}>
          <Box sx={{ width: {xs: '10px', sm: '30px'}, height: '10px' }}>
          </Box>
          {
            sizeSelected.toms ?
            <Box sx={{ margin: {xs: '0 5px 0 0', sm: '0'}, border: '1px solid #fff' }}>
              <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
            </Box>
            :
            <Box sx={{ margin: {xs: '0 5px 0 0', sm: '0'} }}>
              <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
            </Box>
          }
        </Box>
      </Box>
      <ResultsCarousel headData={headData} type={type.toUpperCase()} storeSelected={storeSelected} includeGauge={true} />
    </Box>
  );
}

export default CustomResults;
