export const snareBottomData = [
  {
    id: 1,
    brand: 'Remo',
    model: 'Hazy Ambassador',
    spec1: '3-mil thickness',
    spec2: 'Snare side only',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-ambassador-hazy.webp',
    buyLinks: ['www.remoSS10', 'www.remoSS12', 'www.remoCSS13', 'www.remoSS14'],
    amazonBuyLinks: ['https://amzn.to/3LvP2Nl',
                     'https://amzn.to/3ls5Xpt',
                     'https://amzn.to/3wvCSzL',
                     'https://amzn.to/3Ni552O'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Ambassador-Hazy-Snare-Side-Head-10-in-1274034491053.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-Hazy-Snare-Side-Head-12-in-1274034491201.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-Hazy-Snare-Side-Head-13-in-1274034490683.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-Hazy-Snare-Side-Head-14-in-1274034490618.gc'],
    swBuyLinks: ['none',
                 'https://www.sweetwater.com/store/detail/SA0112--remo-ambassador-snare-side-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/SA0113--remo-ambassador-snare-side-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/SA0114--remo-ambassador-snare-side-drumhead-14-inch']
  },
  {
    id: 2,
    brand: 'Evans',
    model: 'Snare-Side 300',
    spec1: '3-mil thickness',
    spec2: 'Snare side only',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-snare-side-300.webp',
    buyLinks: ['www.evansSS10', 'www.evansSS12', 'www.evansSS13', 'www.evansSS14', 'www.evansSS15'],
    amazonBuyLinks: ['https://amzn.to/3NoRZAO',
                     'https://amzn.to/3aefIWa',
                     'https://amzn.to/3sPHVJo',
                     'https://amzn.to/3lx6map'],
    gcBuyLinks: ['none',
                 'none',
                 'https://www.guitarcenter.com/Evans/300-Snare-Side-Drum-Head-13-in-1274034479551.gc',
                 'https://www.guitarcenter.com/Evans/300-Snare-Side-Drum-Head-14-in-1274034479297.gc'],
    swBuyLinks: ['none',
                 'none',
                 'https://www.sweetwater.com/store/detail/S13H30--evans-snare-side-drumhead-13-inch-clear',
                 'https://www.sweetwater.com/store/detail/S14H30--evans-snare-side-drumhead-14-inch-clear']
  }
];
