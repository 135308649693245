import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeaderModals from '../header/HeaderModals';
import { useState } from 'react';

const Footer = () => {

  const [openFaqModal, setOpenFaqModal] = useState(false);
  const [openAboutModal, setOpenAboutModal] = useState(false);

  const handleFaqOpen = () => setOpenFaqModal(true);
  const handleFaqClose = () => setOpenFaqModal(false);
  const handleAboutOpen = () => setOpenAboutModal(true);
  const handleAboutClose = () => setOpenAboutModal(false);

  return(
    <Box sx={{ width: '100%', height: '140px', backgroundColor: '#131414', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', width: '250px', justifyContent: 'space-around', alignItems: 'center'}}>
        <Button variant="text" sx={{ fontSize: '16px', color: '#ffffffb3'}} onClick={handleAboutOpen}>ABOUT</Button>
        <Button variant="text" sx={{ fontSize: '16px', color: '#ffffffb3'}} onClick={handleFaqOpen}>FAQ</Button>
      </Box>
      <Box sx={{ margin: '20px auto 10px'}}>
        <Typography color="#ffffffb3" sx={{ fontSize: '14px'}}>&copy; DrumheadSelector.com 2023</Typography>
      </Box>
      <HeaderModals openAboutModal={openAboutModal} handleAboutClose={handleAboutClose} openFaqModal={openFaqModal} handleFaqClose={handleFaqClose}/>
    </Box>
  );
}

export default Footer;
