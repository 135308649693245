import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BottomAccordionOuter from './BottomAccordionOuter';
import AffiliateModal from '../affiliateModal/AffiliateModal';
import { useState } from 'react';

const BottomHeads = ({ storeSelected, changeStore }) => {

  const [openAffiliate, setOpenAffiliate] = useState(false);
  const handleAffiliateClose = () => setOpenAffiliate(false);

  return(
    <Box sx={{ padding: {xs: '0 2px 15px', sm: '0 8px 15px', md: '0 10px 20px', lg: '0 15px 25px'}, margin: {xs: '0 auto 50px', sm: '0 auto 60px'}, width: {xs: '98%', sm: '95%', md: '94%', lg: '90%'}, maxWidth: '1200px'  }} >
      <Container sx={{ margin: {xs: '7% auto 3% auto' , md:'6% auto 2% auto'}, display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row'}, flexFlow: 'row wrap', justifyContent: 'space-around', alignItems: 'center', pt: {xs: '30px', md: '80px'} }}>
        <Box className='light-font' sx={{ margin: {xs: '10px auto 20px auto', sm: '10px 0 15px', md: '10px auto 20px auto'}, width: {xs: '90%', sm: '420px', md: '390px', lg: '420px'}, maxWidth: {xs: '400px', sm: '420px', md: '400px', lg: '420px'}, minWidth: '340px', padding: {xs: '0 0 0 2%', sm: '0 0 0 6%', md: '30px 0 0', lg: '35px 0 0'}, alignSelf: {xs: 'flex-start', md: 'flex-end'} }}>
          <Typography variant="h3" component="h3" textAlign="left" sx={{ fontWeight: '300', fontSize: {xs: '32px', sm: '38px', md: '37px', lg: '40px'} }} >
              BOTTOM HEADS <br/>ARE IMPORTANT, TOO.
          </Typography>
          <Typography variant="h5" component="h5" textAlign="left" sx={{ margin: {xs: '20px 0 15px', sm: '30px 0 15px'}, width: { xs: '90%', sm: 'auto'}, maxWidth: {xs: '450px', sm: '380px', md: '365px', lg: '420px'}, fontSize: {xs: '18px', sm: '20px', md: '20px', lg: '23px'} }}>
              Don't worry though - picking the right reso heads is easy.  These are the go-tos for nearly all gigging drummers.
          </Typography>
          <Box sx={{ margin: {xs: '15px 0px 0px 0px', sm: '20px 0px 0px 0px'} }}>
            <Typography onClick={() => setOpenAffiliate(true)} className='affiliate-modal-text' variant="p" component="p" textAlign="left" sx={{ fontStyle: 'italic', fontWeight: '300', fontSize: {xs: '12px', sm: '13px', md: '14px', lg: '15px'} }}>
                *affiliate disclaimer
            </Typography>
            <AffiliateModal openAffiliate={openAffiliate} setOpenAffiliate={setOpenAffiliate} handleAffiliateClose={handleAffiliateClose} />
          </Box>
        </Box>
        <Box component="div" sx={{ margin: {xs: '5px 20px 10px 5px', sm: '10px 0 20px 15px', md: '20px 15px 20px 5px', lg: '20px 25px 20px 0'}, width: {xs: '90%', sm: '70%', md: '43%', lg: '45%'}, minWidth: {xs: '100px', sm: '500px', md: '100px', lg: '100px'}, maxWidth: '600px', padding: {xs: '0', sm: '0 0 15px 0', md: '0 25px 0 0', lg: '0 20px 0 0'} }} >
          <img src='https://drumheadselector.b-cdn.net/shell-pack-gradient.png' alt='drum set' className='drum-image'/>
        </Box>
      </Container>
      <BottomAccordionOuter storeSelected={storeSelected} changeStore={changeStore}/>
  </Box>
  );
}

export default BottomHeads;
