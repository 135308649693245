import Box from '@mui/material/Box';
import StoreIcon from '@mui/icons-material/Store';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import amazonLogo from '../../assets/amazonLogo.png';
import guitarCenterLogo from '../../assets/guitarCenterLogo.png';
import sweetWaterLogo from '../../assets/sweetWaterLogo.jpeg';
import { useState } from 'react';

const StoreChange = ({ display, storeSelected, changeStore }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const openStoreIcon = (event) => {
      setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return(
    <Box>
    {
      display === 'showing' ?
      <Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          >
          <Box sx={{ }}>
              <Paper>
                <Box sx={{ padding: '10px 20px', border: '1px solid #ffffffb3' }}>
                  <Typography variant='h6' component='h6' color="#eceff1" sx={{ textAlign: 'center', fontWeight: '400', fontSize: '18px' }}>Choose Store</Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: '15px', color: '#ffffffb3' }}>Links will be changed to selected option.</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', margin: '20px auto'}}>
                    <Box sx={{ maxWidth: '82px' }} onClick={() => {changeStore('amazon'); handleClose()} }>
                      <img src={amazonLogo} alt='amazon logo' className={`popper-logo ${ storeSelected === 'amazon' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                    <Box sx={{ maxWidth: '82px'}} onClick={() => {changeStore('guitar center'); handleClose()} }>
                      <img src={guitarCenterLogo} alt='guitar center logo' className={`popper-logo ${ storeSelected === 'guitar center' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                    <Box sx={{ maxWidth: '82px'}} onClick={() => {changeStore('sweetwater'); handleClose()} }>
                      <img src={sweetWaterLogo} alt='sweetwater logo' className={`popper-logo ${ storeSelected === 'sweetwater' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
          </Box>
        </Popover>
        <IconButton
          size="large"
          aria-label="store select"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={openStoreIcon}
          color="primary"
          sx={{ padding: '7px'}}
        >
          <StoreIcon  sx={{ fontSize: {xs: '27px', sm: '27px', md: '28px', lg: '30px'} }}/>
        </IconButton>
      </Box>
      :
      <Box></Box>
    }
    </Box>
  );
}

export default StoreChange;
