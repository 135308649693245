export const snareData = [
  {
    id: 1,
    brand: 'Remo',
    model: 'Coated Emperor',
    spec1: '2-ply, 7-mil + 7-mil',
    spec2: 'No dampening/dots',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-emperor-coated.webp',
    buyLinks: ['www.remo10', 'www.remo12', 'www.remo13', 'www.remo14', 'www.remo15', 'www.remo16', 'www.remo18'],
    amazonBuyLinks: ['https://amzn.to/3yTmgDM',
                     'https://amzn.to/3Ni7af8',
                     'https://amzn.to/3MxBUJ5',
                     'https://amzn.to/3luqqu8'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Emperor-Coated-Drum-Head-10-in-1274034491384.gc',
                 'https://www.guitarcenter.com/Remo/Emperor-Coated-Drum-Head-12-in-1274034490892.gc',
                 'https://www.guitarcenter.com/Remo/Emperor-Coated-Drum-Head-13-in-1274034490637.gc',
                 'https://www.guitarcenter.com/Remo/Emperor-Coated-Drum-Head-14-in-1274034491073.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BE0110--remo-emperor-coated-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/BE0112--remo-emperor-coated-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/BE0113--remo-emperor-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/BE0114--remo-emperor-coated-drumhead-14-inch']
  },
  {
    id: 2,
    brand: 'Evans',
    model: 'Coated G12',
    spec1: '1-ply, 12-mil thickness',
    spec2: 'No dampening/dots',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-g12-coated.webp',
    buyLinks: ['www.evansG1210', 'www.evansG1212', 'www.evansG1213', 'www.evansG1214'],
    amazonBuyLinks: ['https://amzn.to/3G3Wuyc',
                     'https://amzn.to/3NlUSlQ',
                     'https://amzn.to/3yQ5wNU',
                     'https://amzn.to/3sR0uwD'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-10-in-1366643219938.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-12-in-1364827971453.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-13-in-1364827971457.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-14-in-1392739390394.gc'],
    swBuyLinks: ['none',
                 'none',
                 'https://www.sweetwater.com/store/detail/B13G12--evans-g12-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/B14G12--evans-g12-coated-drumhead-14-inch']
  },
  {
    id: 3,
    brand: 'Remo',
    model: 'Coated Ambassador X',
    spec1: '1-ply, 12-mil thickness',
    spec2: 'No dampening/dots',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-ambassadorX-coated.webp',
    buyLinks: ['www.remoAmbx10', 'www.remoAmbx12', 'www.remoAmbx13', 'www.remoAmbx14'],
    amazonBuyLinks: ['https://amzn.to/38DMrUk',
                     'https://amzn.to/3wCc4Nq',
                     'https://amzn.to/384l19P',
                     'https://amzn.to/382Owc3'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-10-in-1274034490931.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-12-in-1274034491338.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-13-in-1274034491078.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-14-in-1274034490860.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/AX0110--remo-ambassador-by-coated-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/AX0112--remo-ambassador-by-coated-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/AX0113--remo-ambassador-by-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/AX0114--remo-ambassador-by-coated-drumhead-14-inch']
  },
  {
    id: 4,
    brand: 'Evans',
    model: 'Coated Power Center',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Clear dot on bottom',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-powerCenter-coated.webp',
    buyLinks: ['www.evansPC10', 'www.evansPC12', 'www.evansPC13', 'www.evansPC14'],
    amazonBuyLinks: ['https://amzn.to/382ODEv',
                     'https://amzn.to/3MxwL3v',
                     'https://amzn.to/3sMN2d6',
                     'https://amzn.to/38DuPb2'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/EVANS-B10G1RD-10IN-PWR-CTR-REV-DOT-HEAD-CTD-1316478164987.gc?rNtt=evans%20power%20center%20&index=2',
                 'none',
                 'https://www.guitarcenter.com/Evans/Power-Center-Reverse-Dot-Head-13-in-1274034479265.gc',
                 'https://www.guitarcenter.com/Evans/Power-Center-Reverse-Dot-Head-14-in-1274034478973.gc'],
    swBuyLinks: ['none',
                 'none',
                 'https://www.sweetwater.com/store/detail/B13G1RD--evans-power-center-reverse-dot-13-inch',
                 'https://www.sweetwater.com/store/detail/B14G1RD--evans-power-center-reverse-dot-drumhead-14-inch']
  },
  {
    id: 5,
    brand: 'Remo',
    model: 'Coated Controlled Sound',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Black dot on bottom',
    sizes: [10, 12, 13, 14],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-controlledSound-coated.webp',
    buyLinks: ['www.remoCS10', 'www.remoCS12', 'www.remoCS13', 'www.remoCS14'],
    amazonBuyLinks: ['https://amzn.to/3a77kaN',
                     'https://amzn.to/3sMOY5y',
                     'https://amzn.to/3LrBp1S',
                     'https://amzn.to/3G86qaa'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Controlled-Sound-Reverse-Dot-Coated-Snare-Head-10-in-1274034490672.gc',
                 'https://www.guitarcenter.com/Remo/Controlled-Sound-Reverse-Dot-Coated-Snare-Head-12-in-1274034490909.gc',
                 'https://www.guitarcenter.com/Remo/Controlled-Sound-Reverse-Dot-Coated-Snare-Head-13-in-1274034490671.gc',
                 'https://www.guitarcenter.com/Remo/Controlled-Sound-Reverse-Dot-Coated-Snare-Head-14-in-1274034491118.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/CS0110-10--remo-controlled-sound-coated-drumhead-10-inch-with-black-dot',
                 'https://www.sweetwater.com/store/detail/CS011210--remo-controlled-sound-coated-drumhead-12-inch-with-black-dot',
                 'https://www.sweetwater.com/store/detail/CS011310--remo-controlled-sound-coated-drumhead-13-inch-with-black-dot',
                 'https://www.sweetwater.com/store/detail/CS-0114-10--remo-controlled-sound-coated-drumhead-14-inch-with-black-dot']
  }
]
