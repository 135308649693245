import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import AffiliateModal from '../affiliateModal/AffiliateModal';
import { useState } from 'react';

const QuickTips = ({ storeSelected }) => {

  const [openAffiliate, setOpenAffiliate] = useState(false);
  const handleAffiliateClose = () => setOpenAffiliate(false);

  const handleMoonGel = () => {
    if(storeSelected === 'amazon') {
          window.open('https://amzn.to/3LziimC', "_blank");
    } else if (storeSelected === 'guitar center') {
          window.open('https://www.guitarcenter.com/RTOM/Clear-Moongel.gc?rNtt=moon%20gel&index=2', "_blank");
    } else {
          window.open('https://www.sweetwater.com/store/detail/MoonGel-C--rtom-moongel-drum-damper-pads-clear', "_blank");
        }
  }

  const handleEvansPad = () => {
    if(storeSelected === 'amazon') {
          window.open('https://amzn.to/3Nsam7X', "_blank");
    } else if (storeSelected === 'guitar center') {
          window.open('https://www.guitarcenter.com/Evans/EQ-Pad-Bass-Drum-Muffler-1274034479647.gc', "_blank");
    } else {
          window.open('https://www.sweetwater.com/store/detail/EQPad--evans-eq-pad-bass-drum-muffler', "_blank");
        }
  }



  return(
    <Box sx={{  padding: {xs: '15px 0px 15px', sm: '20px 10px 20px', md: '20px 15px 20px', lg: '30px 25px 20px'}, margin: {xs: '0 auto 30px', sm: '0 auto 35px', md: '0 auto 65px', lg: '0 auto 20px'}, width: {xs: '98%', sm: '565px', md: '800px', lg: '800px'}  }} >

      <Box sx={{ padding: {xs: '15px 15px', sm: '20px 25px'}, margin: '20px auto', pt: {xs: '20px', md: '20px'} }}>
        <Box className='light-font' sx={{ margin: '10px auto 25px auto', width: {xs: '97%', sm: '520px', md: '650px', lg: '700px'},lineHeight: '1.5em' }}>
          <Typography color="primary" variant="h3" component="h3" textAlign="left" sx={{ fontWeight: '300', fontSize: {xs: '30px', sm: '35px'} }} >
                SOME QUICK TIPS:
          </Typography>
          <Box sx={{ display: 'flex', flexFlow: 'row nowrap', margin: '35px 5px', width: {xs: '95%', sm: '100%'} }}>
            <CircleIcon className='circle-icon' color="primary" fontSize="small" sx={{ width: '15px'}}/>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: '0 0 15px 10px'  }}>
                If you can't decide between two similar heads, choosing the one with less thickness or built-in dampening can often be best.  You can always decrease the amount of resonance a head has with external dampening, but you can't add resonance to a head that is too thick or muffled.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexFlow: 'row nowrap', margin: '35px 5px 10px', width: {xs: '93%', sm: '100%'} }}>
            <CircleIcon className='circle-icon' color="primary" fontSize="small" sx={{ width: '15px'}}/>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: '0 0 0 10px'  }}>
               Our recommended dampening products for snares and toms is MoonGel, and for bass drums, we like the Evans EQ Pad.  You will likely need two Evans pads if you want the option of dampening both sides of your bass drum.
            </Typography>
          </Box>
          <Box sx={{ margin: {xs: '5px 0px 40px 28px', sm: '10px 0px 45px 28px'} }}>
            <Typography onClick={() => setOpenAffiliate(true)} className='affiliate-modal-text' variant="p" component="p" textAlign="left" sx={{ fontStyle: 'italic', fontWeight: '300', fontSize: {xs: '12px', sm: '13px', md: '14px', lg: '15px'} }}>
                 *affiliate disclaimer
            </Typography>
            <AffiliateModal openAffiliate={openAffiliate} setOpenAffiliate={setOpenAffiliate} handleAffiliateClose={handleAffiliateClose} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-around', width: {xs: '95%', sm: '500px'}, margin: '0 auto' }} className='tips-products'>
          <Box sx={{ width: {xs: '160px', sm: 'auto'} }}>
            <Box sx={{ width: {xs: '140px', sm: '150px', md: '170px', lg: '170px'}, height: {xs: '140px', sm: '150px', md: '170px', lg: '170px'}, margin: {xs: '0 auto', sm: '0 auto', md: '0 0 0 5px', lg: '0 0 0 5px'} }}>
              <img src={'https://drumheadselector.b-cdn.net/moongel.webp'} alt='moon gel dampening pads' />
            </Box>
            <Box>
              <Typography variant="h6" component="h6" sx={{ color: '#ffffffb3', margin: '5px 0 10px', fontSize: {xs: '17px', sm: '18px', md: '20px', lg: '20px'} }}>RTOM MoonGel</Typography>
            </Box>
            <Box>
              <Button variant="contained" onClick={() => handleMoonGel()} sx={{ fontSize: {xs: '13px', sm: '13px', md: '13px', lg: '13px'}, margin: {xs: '10px 20px 20px', sm: '10px 20px 5px', md: '10px auto', lg: '20px auto'}, padding: {xs: '6px 14px', sm: '6px 14px', md: '5px 9px', lg: '5px 10px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '135px', sm: '135px', md: '135px', lg: '135px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                <Box>See on</Box>
                {
                  storeSelected === 'amazon' ?
                  <Box>Amazon</Box>
                  :
                  (
                    storeSelected === 'guitar center' ?
                    <Box>Guitar Center</Box>
                    :
                    <Box>Sweetwater</Box>
                  )
                }
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: {xs: '160px', sm: 'auto'} }}>
            <Box sx={{ width: {xs: '140px', sm: '150px', md: '170px', lg: '170px'}, height: {xs: '140px', sm: '150px', md: '170px', lg: '170px'}, margin: {xs: '0 auto', sm: '0 auto', md: '0 0 0 5px', lg: '0 0 0 5px'} }}>
              <img src={'https://drumheadselector.b-cdn.net/evans-eq-pad.webp'} alt='evans dampening pad' />
            </Box>
            <Box>
              <Typography variant="h6" component="h6" sx={{ color: '#ffffffb3', margin: '5px 0 10px', fontSize: {xs: '17px', sm: '18px', md: '20px', lg: '20px'} }}>Evans EQ Pad</Typography>
            </Box>
            <Box>
              <Button variant="contained" onClick={() => handleEvansPad()} sx={{ fontSize: {xs: '13px', sm: '13px', md: '13px', lg: '13px'}, margin: {xs: '10px 20px 20px', sm: '10px 20px 5px', md: '10px auto', lg: '20px auto'}, padding: {xs: '6px 14px', sm: '6px 14px', md: '5px 9px', lg: '5px 10px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '135px', sm: '135px', md: '135px', lg: '135px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                <Box>See on</Box>
                {
                  storeSelected === 'amazon' ?
                  <Box>Amazon</Box>
                  :
                  (
                    storeSelected === 'guitar center' ?
                    <Box>Guitar Center</Box>
                    :
                    <Box>Sweetwater</Box>
                  )
                }
              </Button>
            </Box>
          </Box>
        </Box>
          <Box className='light-font' sx={{ margin: {xs: '50px auto 20px auto', sm: '50px auto 25px auto', md: '40px auto 25px auto', lg: '40px auto 25px auto'}, width: {xs: '95%', sm: '500px', md: '650px', lg: '700px'}, lineHeight: '1.5em' }}>
            <Box sx={{ display: 'flex', flexFlow: 'row nowrap', margin: '35px 5px', width: {xs: '95%', sm: '100%'} }}>
              <CircleIcon className='circle-icon' color="primary" fontSize="small" sx={{ width: '15px'}}/>
              <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: '0 0 15px 10px'  }}>
                  Don't forget about bottom heads, particularly on toms.  They don't wear out as quickly as batter-side heads, but every time you hit a batter head, the reso head vibrates and loses a tiny amount of elasticity.  When it's worn out, the tom's tone is severly limited.  Our rule of thumb is every 2-3 times you change the tom batter heads, try to change the resonant heads.  Snare and bass resonant heads can last longer, although not forever.
              </Typography>
            </Box>
          </Box>
      </Box>
  </Box>
  );
}

export default QuickTips;
