export const bassBottomData = [
  {
    id: 1,
    brand: 'Evans',
    model: 'EMAD Reso',
    spec1: '1-ply, 10-mil thickness',
    spec2: '4" port hole w/ foam',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-emad-reso.webp',
    buyLinks: ['www.remoCS18', 'www.remoCS20', 'www.remoCS22', 'www.remoCS24', 'www.remoCS26'],
    amazonBuyLinks: ['https://amzn.to/3ww0vZ0',
                     'https://amzn.to/3LCeiBI',
                     'https://amzn.to/382h8lI',
                     'https://amzn.to/3lz8gHi',
                     'https://amzn.to/3PKVdkj'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/EMAD-Resonant-Bass-Drum-Head-Black-18-in-1274034479294.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Resonant-Bass-Drum-Head-Black-20-in-1274034479423.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Resonant-Bass-Drum-Head-Black-22-in-1274034479182.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Resonant-Bass-Drum-Head-Black-24-in-1274034479547.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Resonant-Bass-Drum-Head-26-in-1296839766934.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BD18REMAD--evans-emad-resonant-black-bass-drumhead-18-inch',
                 'https://www.sweetwater.com/store/detail/BD20REMAD--evans-emad-resonant-black-bass-drumhead-20-inch',
                 'https://www.sweetwater.com/store/detail/BD22REMAD--evans-emad-resonant-bass-drumhead-22-inch-ebony',
                 'https://www.sweetwater.com/store/detail/BD24REMAD--evans-emad-resonant-bass-drumhead-24-inch-ebony',
                 'none']
  },
  {
    id: 2,
    brand: 'Evans',
    model: 'EQ3 Black Reso',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring & 5" Port hole',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-eq3-reso-black.webp',
    buyLinks: ['www.evansEQ18', 'www.evansEQ20', 'www.evansEQ22', 'www.evansEQ24', 'www.evansEQ26'],
    amazonBuyLinks: ['https://amzn.to/386EGpM',
                     'https://amzn.to/3PyE259',
                     'https://amzn.to/3LCObKN',
                     'https://amzn.to/3wDZ7CK',
                     'https://amzn.to/3lrxp6U'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/EQ3-Black-Resonant-Bass-Drumhead-18-in-1274034479790.gc',
                 'https://www.guitarcenter.com/Evans/EQ3-Black-Resonant-Bass-Drumhead-20-in-1274034479228.gc',
                 'https://www.guitarcenter.com/Evans/EQ3-Black-Resonant-Bass-Drumhead-22-in-1274034478989.gc',
                 'https://www.guitarcenter.com/Evans/EQ3-Black-Resonant-Bass-Drumhead-24-in-1274034479730.gc',
                 'https://www.guitarcenter.com/Evans/EQ3-Black-Resonant-Bass-Drumhead-26-in-1274034479545.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BD18RB--evans-eq3-black-resonant-bass-drumhead-18-inch-with-port',
                 'https://www.sweetwater.com/store/detail/BD20RB--evans-eq3-black-resonant-bass-drumhead-20-inch-with-port',
                 'https://www.sweetwater.com/store/detail/BD22RB--evans-eq3-black-bass-resonant-drumhead-22-inch-with-port',
                 'https://www.sweetwater.com/store/detail/BD24RB--evans-eq3-black-resonant-bass-drumhead-24-inch-with-port',
                 'none']
  },
  {
    id: 3,
    brand: 'Evans',
    model: 'EQ3 Smooth White Reso',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring & 5" Port hole',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-eq3-white-reso.webp',
    buyLinks: ['www.remoPSE18', 'www.remoPSE20', 'www.remoPSE22', 'www.remoPSE24', 'www.remoPSE26'],
    amazonBuyLinks: ['https://amzn.to/3LCfKEa',
                     'https://amzn.to/3wwu1xV',
                     'https://amzn.to/3G6JpEd',
                     'https://amzn.to/3G1YIOC',
                     'https://amzn.to/3MyBfqV'],
    gcBuyLinks: ['none',
                 'none',
                 'none',
                 'none',
                 'none'],
    swBuyLinks: ['none',
                 'none',
                 'https://www.sweetwater.com/store/detail/BD22RSW--evans-eq3-smooth-white-bass-resonant-head-22-inch',
                 'none',
                 'none']
  },
  {
    id: 4,
    brand: 'Evans',
    model: 'Calftone Reso',
    spec1: '1-ply, 12-mil thickness',
    spec2: 'No dampening ring/port hole',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-calftone-reso.webp',
    buyLinks: ['www.evansPCB18', 'www.evansPCB20', 'www.evansPCB22', 'www.evansPCB24', 'www.evansPCB26'],
    amazonBuyLinks: ['https://amzn.to/3G3OeOF',
                     'https://amzn.to/3MyBoKZ',
                     'https://amzn.to/3wGo0yZ',
                     'https://amzn.to/3luQYeC',
                     'https://amzn.to/3luWTjS'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/Calftone-Bass-Drum-Head-18-in-1500000016535.gc',
                 'https://www.guitarcenter.com/Evans/Calftone-Bass-Drum-Head-20-in-1500000016534.gc',
                 'https://www.guitarcenter.com/Evans/Calftone-Bass-Drum-Head-22-in-1500000016536.gc',
                 'https://www.guitarcenter.com/Evans/Calftone-Bass-Drum-Head-24-in-1500000016532.gc',
                 'https://www.guitarcenter.com/Evans/Calftone-Bass-Drum-Head-26-in-1500000016533.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BD18CT--evans-calftone-bass-drumhead-18-inch',
                 'https://www.sweetwater.com/store/detail/BD20CT--evans-calftone-bass-drumhead-20-inch',
                 'https://www.sweetwater.com/store/detail/BD22CT--evans-calftone-bass-drumhead-22-inch',
                 'https://www.sweetwater.com/store/detail/BD24CT--evans-calftone-bass-drumhead-24-inch',
                 'https://www.sweetwater.com/store/detail/BD26CT--evans-calftone-bass-drumhead-26-inch']
  },
  {
    id: 5,
    brand: 'Remo',
    model: 'Ebony Powerstroke P3',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring & DynamO',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-p3-ebony-reso.webp',
    buyLinks: ['www.evansCT18', 'www.evansCT20', 'www.evansCT22', 'www.evansCT24', 'www.evansCT26'],
    amazonBuyLinks: ['https://amzn.to/3sMKZG8',
                     'https://amzn.to/3wtM9IP',
                     'https://amzn.to/3PrL7EJ',
                     'https://amzn.to/3lwneOk',
                     'none'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Ebony-Powerstroke-3-Resonant-Bass-Drum-Head-18-in-1274034491146.gc',
                 'https://www.guitarcenter.com/Remo/Ebony-Powerstroke-3-Resonant-Bass-Drum-Head-20-in-1274034491618.gc',
                 'https://www.guitarcenter.com/Remo/Ebony-Powerstroke-3-Resonant-Bass-Drum-Head-22-in-1274034490641.gc',
                 'https://www.guitarcenter.com/Remo/Ebony-Powerstroke-3-Resonant-Bass-Drum-Head-24-in-1274034491315.gc',
                 'https://www.guitarcenter.com/Remo/Ebony-Powerstroke-3-Resonant-Bass-Drum-Head-26-in-1274034490684.gc'],
    swBuyLinks: ['none',
                 'none',
                 'none',
                 'none',
                 'none']
  },
]
