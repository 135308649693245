import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RecAccordionOuter from './RecAccordionOuter';
import AffiliateModal from '../affiliateModal/AffiliateModal';
import { useState } from 'react';

const Recommendations = ({ storeSelected, changeStore }) => {

  const [openAffiliate, setOpenAffiliate] = useState(false);
  const handleAffiliateClose = () => setOpenAffiliate(false);




  return(
    <Box id='recsRef' sx={{ padding: {xs: '0 2px 15px', sm: '0 6px 15px', md: '0 8px 20px', lg: '0 15px 25px'}, margin: '0 auto 60px', width: {xs: '98%', sm: '95%', md: '93%', lg: '90%'}, maxWidth: '1200px' }} >
      <Container sx={{ margin: {xs: '5% auto 3% auto' , sm: '2% auto 3% auto', md:'35px auto 2% auto', lg: '75px auto 2% auto'}, maxWidth: {lg: '1100px'}, display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row'}, flexFlow: 'row wrap', justifyContent: 'space-around', alignItems: 'center', pt: {xs: '10px', sm: '20px', md: '30px', lg: '35px'} }}>
        <Box className='light-font' sx={{ margin: {xs: '10px auto 20px auto', sm: '10px 0px 15px', md: '10px auto 20px auto'}, width: {xs: '90%', sm: '420px', md: '370px', lg: '400px'}, maxWidth: {xs: '400px', sm: '420px', md: '400px'}, minWidth: '340px', padding: {xs: '0 0 0 2%', sm: '0 0 0 6%', md: '30px 0 0', lg: '20px 0 0'}, alignSelf: {xs: 'flex-start', md: 'flex-end'} }}>
          <Typography variant="h3" component="h3" textAlign="left" sx={{ fontWeight: '300', fontSize: {xs: '34px', sm: '38px', md: '40px', lg: '44px'} }} >
              NOT SURE <br/>WHERE TO START?
          </Typography>
          <Typography variant="h5" component="h5" textAlign="left" sx={{ margin: {xs: '20px 0 20px', sm: '30px 0 15px'}, width: { xs: '95%', sm: 'auto'}, maxWidth: {xs: '300px', sm: '335px', md: '335px', lg: '410px'}, fontSize: {xs: '19px', sm: '21px', md: '21px', lg: '24px'} }}>
              These popular all-purpose heads work in just about any situation.
          </Typography>
          <Box sx={{ margin: {xs: '15px 0px 0px 0px', sm: '20px 0px 0px 0px'} }}>
            <Typography onClick={() => setOpenAffiliate(true)} className='affiliate-modal-text' variant="p" component="p" textAlign="left" sx={{ fontStyle: 'italic', fontWeight: '300', fontSize: {xs: '12px', sm: '13px', md: '14px', lg: '15px'} }}>
                 *affiliate disclaimer
            </Typography>
            <AffiliateModal openAffiliate={openAffiliate} setOpenAffiliate={setOpenAffiliate} handleAffiliateClose={handleAffiliateClose} />
          </Box>
        </Box>
        <Box component="div" sx={{ margin: {xs: '8px 22px 10px 3px', sm: '10px 0 10px 15px', md: '20px 15px 20px 5px', lg: '20px 20px 20px 0'}, width: {xs: '93%', sm: '70%', md: '45%', lg: '45%'},  minWidth: {xs: 'auto', sm: '425px', md: 'auto'}, maxWidth: '600px', padding: {xs: '0 0 10px 0', sm: '0 0 15px 0', md: '0 25px 0 0', lg: '0 20px 0 0'}, alignSelf: {xs: 'auto', md: 'flex-end'} }}>
         <Box>
          <Box component="img" alt="drum" sx={{ content: {xs: `url(${'https://drumheadselector.b-cdn.net/drumset-gradient-b.png'})`, md: `url(${'https://drumheadselector.b-cdn.net/snare-drum-gradient-left.png'})`}, maxWidth: {xs: '92%', sm: '80%', md: '95%', lg: '98%'} }}>
          </Box>
        </Box>
        </Box>
      </Container>
    <RecAccordionOuter storeSelected={storeSelected} changeStore={changeStore}/>
  </Box>
  );
}

export default Recommendations;
