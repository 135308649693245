import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import '../../App.css';

const Main = () => {

  const navBarHeight = '70px';
  const selectorRef = document.getElementById('selectorRef');
  const scrollToSelector = () => {
    selectorRef.scrollIntoView({ behavior: 'smooth' });
  }

  return(
    <Box>
      <Box sx={{ position: 'relative', padding: '0px', margin: {xs: '0 auto 2.5%', sm: '0 auto 40px', md: '0 auto 10px', lg: '0 auto 40px'}, width: '90%', maxWidth: {xs: '900px', lg: '1200px'}, height: {xs: `calc(98svh - ${navBarHeight})`, sm: '87vh', md: '90vh', lg: '88vh'}, minHeight: {xs: `calc(95svh - ${navBarHeight})`, sm: '675px', md: 'auto'}, maxHeight: {xs: '770px', sm: '1000px'}, display: 'flex', flexFlow: {xs: 'column nowrap', sm: 'auto'}, justifyContent: 'space-around' }}>
        <Box sx={{ margin: {xs: '0 auto 6% auto' , sm: '0 auto 9vh auto', md:'1vh auto 12vh auto', lg: '1vh auto 13vh auto'}, display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row'}, flexFlow: 'row wrap', justifyContent: 'space-around', alignItems: 'center', padding: {xs: '0px', sm: '10px 30px 0 0', md: '7vh 0 0 0', lg: '9vh 0 0 3%'}, width: {xs: 'auto', sm: 'auto', md: '98%', lg: '85%'}, height: 'auto', minHeight: {xs: '450px', sm: '555px', md: 'auto'} }}>
           <Box className='light-font' sx={{ margin: {xs: '4px auto 6% auto', sm: '10px 0 25px 0', md: '10px 0 35px 0'}, padding: {xs: '0 0 0 3.5%', sm: '0 0 0 6%', md: '0 0 0 4%', lg: '0 0 0 6%'}, width: {xs: '98%', sm: '520px', md: '320px', lg: '38%'}, maxWidth: {xs: '400px', sm: '500px', md: '400px'}, minWidth: '300px', alignSelf: {xs: 'flex-start'} }}>
              <Box sx={{ margin: {xs: '3% 0 0 11%', sm: '0 0 0 20%', md: '0'}, width: {xs: '89%', sm: 'auto'} }}>
                <Box>
                  <Typography variant="h1" component="h1" textAlign="left" sx={{ fontSize: {xs: '5svh', sm: '45px', md: '43px', lg: '48px'}, margin: {xs: '3% 0 4svh', sm: 'auto'} }}>
                      DRUMHEAD <br/>SELECTION <br/>MADE SIMPLE.
                  </Typography>
                </Box>
               <Box>
                <Box sx={{ width: { xs: '95%', sm: '320px', md: 'auto'}, maxWidth: {xs: '380px', sm: '320px', md: '360px', lg: '410px'} }}>
                  <Typography variant="h2" component="h2" textAlign="left" sx={{ margin: {xs: '5% 0 3%', sm: '3vh 0 2vh'}, fontSize: {xs: '2.2vh', sm: '2.2vh', md: '19px', lg: '20px'}, fontWeight: '400' }}>
                      Get personalized drumhead <br />recommendations in 3 easy steps.
                  </Typography>
                </Box>
                 <Box sx={{ display: 'flex', alignItems: 'center', padding: {xs: '2px 5px 2px 8px', sm: '3px 5px 3px 20px', md: '5px 5px 5px 18px'} }}>
                    <LooksOneIcon fontSize="medium" className='looks-icon' />
                    <Typography variant="p" component="p" sx={{ fontSize: {xs: '2.1svh', sm: '1.9vh', md: '16px', lg: '17px'}, fontWeight: '500' }}>
                      Select your drum specs
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: {xs: '2px 5px 2px 8px', sm: '3px 5px 3px 20px', md: '5px 5px 5px 18px'} }}>
                    <LooksTwoIcon fontSize="medium" className='looks-icon' />
                    <Typography variant="p" component="p" sx={{ fontSize: {xs: '2.1svh', sm: '1.9vh', md: '16px', lg: '17px'}, fontWeight: '500' }}>
                      Select your playing style
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: {xs: '2px 5px 2px 8px', sm: '3px 5px 3px 20px', md: '5px 5px 5px 18px'} }}>
                    <Looks3Icon fontSize="medium" className='looks-icon' />
                    <Typography variant="p" component="p" sx={{ fontSize: {xs: '2.1svh', sm: '1.9vh', md: '16px', lg: '17px'}, textAlign: 'left', fontWeight: '500' }}>
                      Select your preferred sound
                    </Typography>
                  </Box>
              </Box>
              </Box>
          </Box>
          <Box component="div" sx={{ margin: {xs: '0 9% 1.5% 0px', sm: '0 15px 2.5vh 10px', md: '0 0 50px 0', lg: '0 0 30px 0'}, width: {xs: '59%', sm: '53%', md: '46%', lg: '50%'}, maxWidth: {xs: '375px', sm: '475px', md: '450px', lg: '600px'}, padding: {xs: '0', sm: '0', md: '0', lg: '0 0 0 30px'}, alignSelf: {xs: 'auto', md: 'flex-end'} }} >
            <Box>
              <Box component="img" alt="drum" sx={{ content: {xs: `url(${'https://drumheadselector.b-cdn.net/snare-drum-gradient-left.png'})`, md: `url(${'https://drumheadselector.b-cdn.net/drumset-gradient-b.png'})`}, maxWidth: '100%'}}>
              </Box>
            </Box>
          </Box>
        </Box>
      <Box className='toSelectorDiv' sx={{ width: 'auto', border: '2px solid #2196f3', borderRadius: '5px', padding: '2px 5px 2px 12px', margin: {xs: '0 auto', sm: '0 auto'}, display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center'}} onClick={() => scrollToSelector()}>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
          <Typography variant="p" component="p" textAlign="center" sx={{ fontSize: '18px', color: '#2196f3', fontWeight: '500'}}>SELECT NOW</Typography>
          <ArrowDownwardIcon fontSize="medium" className='down-arrow-icon' sx={{ alignSelf: 'start', color: '#2196f3' }} />
          </Box>
      </Box>
    </Box>
  </Box>
  );
}

export default Main;
