import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, useEffect } from 'react';

const minDistance = 0;

const marks = [
  {
    value: 8,
    label: '8"',
  },
  {
    value: 9.7,
    label: '10"',
  },
  {
    value: 11.4,
    label: '12"',
  },
  {
    value: 12.9,
    label: '13"',
  },
  {
    value: 14.4,
    label: '14"',
  },
  {
    value: 16.2,
    label: '16"',
  },
  {
    value: 18,
    label: '18"',
  },
];

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

const TomSpecs = ({ updateSpecs, fullyBuilt, setFullyBuilt }) => {

    const [tomBuild, setTomBuild] = useState({
      diameters: [8, 18],
      shell: null,
      lugs: null,
      hoops: null,
      mounts: null,
      quality: null,
      era: null
    });

    let nullCount = 0;

    const checkTomBuild = () => {
      for(const prop in tomBuild) {
        // console.log(tomBuild[prop]);
        if(tomBuild[prop] !== null) {
          nullCount += 1;
          if(nullCount === 6) {
            // console.log('6');
            setFullyBuilt(true);
          } else {
            // console.log('nulls: ', nullCount);
          }
        }
      }
    }

    useEffect(() => {
      // console.log('useEffect ', tomBuild);
      checkTomBuild();
    });

    const recsRef = document.getElementById('recsRef');
    const scrollToRecs = () => {
      recsRef.scrollIntoView({ behavior: 'smooth' });
    };

    const [diameters, setDiameters] = useState([8, 18]);
    const [valueShowing, setValueShowing] = useState([8, 18]);

    let sliderValues = [];
      const handleChange1 = (event, newValue, activeThumb) => {
        sliderValues = [newValue[0], newValue[1]];
        if (!Array.isArray(newValue)) {
          return;
        }
        if (activeThumb === 0) {
          setValueShowing([Math.min(newValue[0], valueShowing[1] - minDistance), valueShowing[1]]);
          // console.log(sliderValues);
          let diametersValue = event.target.value;
          setDiameters(diametersValue);
          setTomBuild({...tomBuild, diameters: diametersValue});
        } else {
          setValueShowing([valueShowing[0], Math.max(newValue[1], valueShowing[0] + minDistance)]);
          // console.log(sliderValues);
          let diametersValue = event.target.value;
          setDiameters(diametersValue);
          setTomBuild({...tomBuild, diameters: diametersValue});
        }
      };

    const [shell, setShell] = useState('');
    const handleShellChange = (event) => {
      let shellValue = event.target.value;
      setShell(shellValue);
      setTomBuild({...tomBuild, shell: shellValue});
    };

    const [hoops, setHoops] = useState('');
    const handleHoopsChange = (event) => {
      let hoopsValue = event.target.value;
      setHoops(hoopsValue);
      setTomBuild({...tomBuild, hoops: hoopsValue});
    };

    const [mounts, setMounts] = useState('');
    const handleMountsChange = (event) => {
      let mountsValue = event.target.value;
      setMounts(mountsValue);
      setTomBuild({...tomBuild, mounts: mountsValue});
    };

    const [quality, setQuality] = useState('');
    const handleQualityChange = (event) => {
      let qualityValue = event.target.value;
      setQuality(qualityValue);
      setTomBuild({...tomBuild, quality: qualityValue});
    };

    const [era, setEra] = useState('');
    const handleEraChange = (event) => {
      let eraValue = event.target.value;
      setEra(eraValue);
      setTomBuild({...tomBuild, era: eraValue});
    };

    const thinHoop = '< 2mm';
    const mediumHoop = '2mm - 2.5mm';
    const thickHoop = '> 2.5mm';

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const startOver = (e) => {
      setFullyBuilt(false);
      nullCount = 0;
        setTomBuild({
          diameters: [8, 18],
          shell: null,
          lugs: null,
          hoops: null,
          mounts: null,
          quality: null,
          era: null
        });
        setDiameters([8, 18]);
        setShell('');
        setHoops('');
        setMounts('')
        setQuality('');
        setEra('');
      }

  return(

    <Box sx={{ height: {xs: '500px',  sm: '535px', md: '450px', lg: '450px'}, maxWidth: {xs: '700px', lg: '800px'}, width: { xs: '90%', sm: '90%', md: '85%'}, padding: {xs: '3px 10px 10px 10px', sm: '10px'}, margin: '0 auto' }}>
      <Box sx={{ height: {xs: '415px', sm: '435px', md: '350px'} }}>
        <Box sx={{ flexGrow: 1, margin: '0 10px 20px' }}>
          <Typography variant="h4" component="h4" color="#ffffffb3" sx={{ display: 'inline', fontSize: '20px', fontWeight: '500', padding: '5px'}}>
            SELECT YOUR SPECS
          </Typography>
          <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleOpen}/>
        </Box>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='modal-style' sx={{ backgroundColor: '#eceff1'}}>
              <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'} }}>
                <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500' }}>
                  TOMS SPEC HELP
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  If you don't know or can't find out certain specifications, estimate with the information below.  If an educated guess is made with one or two of the specs, the result should still generate good results.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  DIAMETER
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  A drum's diameter is measured across the head, from the inside of the hoop on one side to the other.  The hoops are not included in this measurement.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  SHELL MATERIAL
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  The shell material is often made clear by the model name or it can be found with a simple Google search.  For hybrid shells, select the most prominent wood.  If you have an unknown student drum set, it is likely made of either basswood or poplar.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  HOOP TYPE
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  Triple-flanged hoops are installed on the vast majority of drums.  If you don't know how thick your hoops are - budget drums typically have thin flanged hoops, semi-pro drums typically have medium flanged hoops, and professional drums typically have medium flanged, thick flanged, die-cast, or wood hoops.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  RACK TOM MOUNTS
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  Suspension mounts come in many variations, but all allow the drum to resonate more.  They tend to be standard on today's mid-level and professional drum kits.  Select 'direct to shell' if the rack tom brackets are mounted like those of floor tom leg brackets.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  QUALITY LEVEL
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  As a rough estimation, budget drum sets typically sell new with hardware and cymbals for between $300 - $900.  Semi-pro kits are usually sold as shell packs (no hardware or cymbals), although not always, and are typically in the $600 - $1300 range.  New professional drum sets are almost always sold as shell packs and typically sell for at least $1300 and up.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  ERA
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  If you don't know the approximate date of a drum, some research may need to be done.  If you can't determine its age, make an educated guess based on drum badges, colors, etc.
                </Typography>
              </Box>
            </Box>
          </Modal>

        <Grid sx={{ justifyContent: 'center', mb: {xs: '26px', sm: '40px'} }} container columnSpacing={2} rowSpacing={{xs: '29px', sm: '32px'}}>
          <Grid item xs={2} md={4}>
          </Grid>
          <Grid item xs={8} md={4} sx={{ height: {xs: '103px', sm: '103px'} }}>
            <FormControl fullWidth sx={{maxWidth: '360px'}}>
              <Typography sx={{fontSize: '14px'}} className='tom-range'>diameter range</Typography>
              <Slider
                min={8}
                max={18}
                getAriaLabel={() => 'Minimum distance'}
                value={diameters}
                onChange={handleChange1}
                valueLabelDisplay="off"
                valueLabelFormat={valueLabelFormat}
                step={null}
                disableSwap
                marks={marks}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} md={4}>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth sx={{maxWidth: '350px'}}>
              <InputLabel id="demo-simple-select-label">Shell Material</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={shell}
                  label="Shell Material"
                  onChange={handleShellChange}
                  >
                <MenuItem value={'acrylic'}>Acrylic</MenuItem>
                <MenuItem value={'basswood'}>Basswood</MenuItem>
                <MenuItem value={'birch'}>Birch</MenuItem>
                <MenuItem value={'mahogany'}>Mahogany</MenuItem>
                <MenuItem value={'maple'}>Maple</MenuItem>
                <MenuItem value={'oak'}>Oak</MenuItem>
                <MenuItem value={'poplar'}>Poplar</MenuItem>
                <MenuItem value={'other'}>Other/Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth sx={{maxWidth: '350px'}}>
              <InputLabel id="demo-simple-select-label">Hoop Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hoops}
                  label="Hoop Type"
                  onChange={handleHoopsChange}
                  >
                  <MenuItem value={'thin'}><Typography>Thin Flanged ({thinHoop})</Typography></MenuItem>
                  <MenuItem value={'medium'}>Medium Flanged ({mediumHoop})</MenuItem>
                  <MenuItem value={'thick'}>Thick Flanged ({thickHoop})</MenuItem>
                  <MenuItem value={'die-cast'}>Die-Cast</MenuItem>
                  <MenuItem value={'wood'}>Wood</MenuItem>
                  <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth sx={{maxWidth: '350px'}}>
              <InputLabel id="demo-simple-select-label">Rack Tom Mounts</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mounts}
                  label="Rack Tom Mounts"
                  onChange={handleMountsChange}
                  >
                <MenuItem value={'suspension'}><Typography>Suspension Mounts</Typography></MenuItem>
                <MenuItem value={'direct'}>Direct To Shell</MenuItem>
                <MenuItem value={'snare-basket'}>Snare Basket</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth sx={{maxWidth: '350px'}}>
              <InputLabel id="demo-simple-select-label">Quality Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quality}
                  label="Quality Level"
                  onChange={handleQualityChange}
                  >
                  <MenuItem value={'budget'}>Budget</MenuItem>
                  <MenuItem value={'semi-pro'}>Semi-Pro</MenuItem>
                  <MenuItem value={'professional'}>Professional</MenuItem>
                  <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth sx={{maxWidth: '350px'}}>
              <InputLabel id="demo-simple-select-label">Era</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={era}
                  label="Era"
                  onChange={handleEraChange}
                  >
                <MenuItem value={'pre-1970s'}>Pre-1970s</MenuItem>
                <MenuItem value={'1970s'}>1970s</MenuItem>
                <MenuItem value={'1980s'}>1980s</MenuItem>
                <MenuItem value={'1990s'}>1990s</MenuItem>
                <MenuItem value={'2000s'}>2000s</MenuItem>
                <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {
        fullyBuilt ?
        <Button variant="contained" onClick={() => updateSpecs(tomBuild)}>
          Next
        </Button>
        :
        <Tooltip title="Fill out all specs" placement="top">
        <span>
          <Button variant="contained" disabled >
            Next
          </Button>
          </span>
        </Tooltip>
      }
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignSelf: 'center'}}>
        <Box sx={{ width: '30px', height: '30px'}}>
        </Box>
        <Box>
          <Typography id="modal-modal-description" sx={{ margin: {xs: '10px 0 12px', sm: '16px 0'} }} className="start-over" onClick={startOver}>
            start over
          </Typography>
        </Box>
        <Box sx={{ width: '30px', height: '30px'}}>
          <Box sx={{ display: {xs: 'block', md: 'block' }}} >
            <ArrowDownwardIcon fontSize="medium" className='down-arrow-icon' sx={{ alignSelf: 'start', fontSize: {xs: '24px', md: '28px', lg: '30px'} }} onClick={() => scrollToRecs()}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TomSpecs;
