import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import logo from '../../assets/dhsLogo.png';
import { useState } from 'react';

const Construction = ({ signedIn, setSignedIn }) => {

  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [signPage, setSignPage] = useState('signin');
  const handleSignInOpen = () => setOpenSignInModal(true);
  const handleSignInClose = () => {
    setOpenSignInModal(false);
    setUsername('');
    setPassword('');
 }

  const sendUserPassword = (usernameInput, passwordInput) => {
    if(signPage === 'signup') {
      let user = {
        username: usernameInput,
        password: passwordInput
      }
      fetch('https://dhs-app-77rpq.ondigitalocean.app/signup', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
      })
      .then(response => response.json())
      .then(data => {
        if(data === 'signup success') {
          setUsername('');
          setPassword('');
          setSignPage('signin')
          setSignedIn(true);
        } else {
          alert('User not authorized');
          setUsername('');
          setPassword('');
        }
      });
    }
      //
      //if not checked, registered user in db
      else {
      // console.log('current user');
      let user = {
        username: username,
        password: password
      }  //  https://dhs-app-77rpq.ondigitalocean.app/
      fetch('https://dhs-app-77rpq.ondigitalocean.app/signin', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
      })
      .then(response => response.json())
      .then(data => {
        if(data === 'signin success') {
          setUsername('');
          setPassword('');
          setSignedIn(true);
        } else {
          alert('Username & password do not match');
          setUsername('');
          setPassword('');
        }
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let isInvalid = [];
    if(username.length < 4) {
      isInvalid.push('username ');
    }
    if(password.length < 5) {
      isInvalid.push('password ');
    }
    if(isInvalid.length === 0) {
      sendUserPassword(username, password);
      setUsername('');
      setPassword('');
    } else {
      alert('Usernames less than 5 characters and passwords less than 8 characters are invalid.  Please try again.');
      setUsername('');
      setPassword('');
    }
  }

  const clearInputs = () => {
    setUsername('');
    setPassword('');
    handleSignInClose();
  }

  //  image --  <a href="https://www.vecteezy.com/free-vector/drum">Drum Vectors by Vecteezy</a>

  return(
    <Box>
       <Box sx={{ padding: {xs: '20px 5px', sm: '20px', md: '30px 20px 15px'}, width: {xs: '95%', sm: '94%', md: '95%'}, maxWidth: '1500px', margin: '0 auto', height: '90vh', minHeight: {xs: '350px', sm: '430px', md: '450px', lg: '450px'}, maxHeight: {xs: '90vh', sm: '675px', md: '725px', lg: '850px'}, overflowY: 'hidden' }}>
         <Box sx={{ display:'flex', flexFlow: 'row nowrap', justifyContent: 'center'}}>
           <Box sx={{ width: {xs: '140px', sm: '150px', md: '170px', lg: '205px'} }}>
             <img src={logo} alt='logo' className='construction-pic' />
           </Box>
         </Box>
         <Box sx={{height: '81vh', maxHeight: {xs:'90vh', sm: '675px', md: '725px', lg: '850px'}, minHeight: {xs: '350px', sm: '380px', md: '400px', lg: '420px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center', paddingTop: {xs: '15px', sm: '25px'} }}>
           <Box>
             <Box sx={{ margin: {xs: '0px auto 20px', sm: '20px auto 20px', md: '35px auto 20px', lg: '40px auto 20px'} }}>
               <Typography variant="h3" component="h3" sx={{ color: '#cecece', fontSize: {xs: '40px', sm: '45px', md: '50px', lg: '55px'}, fontWeight: '300' }}>UNDER CONSTRUCTION</Typography>
             </Box>
             <Box>
               <Typography variant="h4" component="h4" sx={{ color: '#cecece', fontSize: {xs: '16px', sm: '20px', md: '22px', lg: '24px'}, fontWeight: {xs: '300', md: '400'} }}>We're currently working to bring this site live.</Typography>
             </Box>
           </Box>
           <Box sx={{ width: {xs: '65%', sm: '320px', md: '350px', lg: '380px'}, margin: '6vh auto', padding: '5px'}} onClick={handleSignInOpen}>
             <img component='image' src='https://drumheadselector.b-cdn.net/drumset-gradient-b.png' alt='drum set' className='construction-pic'/>
           </Box>
         </Box>
         {
          signPage === 'signin' ?
         <Box>
           <Modal
             open={openSignInModal}
             onClose={handleSignInClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
           >
             <Box  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', sm: '80%', md: '70%', lg: '600px'}, maxWidth: '600px', backgroundColor: '#191a1a'}} >
               <Box sx={{  margin: '0 auto', border: '1px solid #ffffffb3', height: {xs: '50vh', sm: '335px', md: '335px'}, minHeight: '420px', overflowY: 'hidden', padding: {xs: '20px 20px 15px 20px', sm: '25px 25px 20px 25px', md: '35px 35px 25px 35px'} }}>
                 <Box sx={{ width: {xs: '90%', sm: '94%'}, margin: '15px auto 70px', padding: '0 0 0 5%', background: 'linear-gradient(to bottom, #191a1a 0%, #191a1a 47%, #2196f3 47%, #2196f3 52%, #191a1a 52%, #191a1a 100%)'}}>
                   <Box sx={{ backgroundColor: '#191a1a', width: {xs: '32%', sm: '25%', md: '25%', lg: '25%'}, padding: '0 10px'}}>
                     <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#2196f3', fontSize: {xs: '22px', sm: '26px', md: '31px'}, fontWeight: '300', textAlign: 'center'}}>
                       SIGN IN
                     </Typography>
                   </Box>
                 </Box>
                 <Box sx={{ width: '97%', margin: '0 auto', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                   <form onSubmit={handleSubmit}>
                     <Box sx={{ display: 'flex', flexFlow: {xs: 'column nowrap', sm: 'row nowrap'}, justifyContent: 'space-around', margin: '0 auto 50px', width: {xs: '65%', sm: '400px'} }}>
                       <FormControl sx={{ margin: {xs: '0 0 25px', sm: '0'} }}>
                         <InputLabel htmlFor="username">Username</InputLabel>
                         <Input id="construction-username" aria-describedby="my-helper-text" value={username} onChange={(e) => setUsername(e.target.value)}/>
                       </FormControl>
                       <FormControl>
                         <InputLabel htmlFor="password">Password</InputLabel>
                         <Input id="construction-password" aria-describedby="my-helper-text" value={password} type="password" onChange={(e) => setPassword(e.target.value)}/>
                       </FormControl>
                     </Box>
                     <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto 15px' }}>
                       <FormControl>
                         <Button variant="contained" type='submit' sx={{ color: '#131414' }}>Submit</Button>
                       </FormControl>
                     </Box>
                     <Box sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', alignItems: 'center' }}>
                       <FormControl>
                         <Typography variant="p" component="p" className='log-in' sx={{ color: '#cecece', fontSize: {xs: '16px', sm: '16px', md: '16px', lg: '16px'}, fontWeight: '400', padding: '5px' }} onClick={clearInputs}>Cancel</Typography>
                       </FormControl>
                     </Box>
                   </form>
                 </Box>
               </Box>
             </Box>
           </Modal>
         </Box>
         :
         <Box>
           <Modal
             open={openSignInModal}
             onClose={handleSignInClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
           >
             <Box  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', sm: '80%', md: '70%', lg: '600px'}, maxWidth: '600px', backgroundColor: '#131414'}} >
               <Box sx={{  margin: '0 auto', border: '1px solid #ffffffb3', height: {xs: '50vh', sm: '335px', md: '335px'}, minHeight: '420px', overflowY: 'hidden', padding: {xs: '20px 20px 15px 20px', sm: '25px 25px 20px 25px', md: '35px 35px 25px 35px'} }}>
                 <Box sx={{ width: {xs: '90%', sm: '94%'}, margin: '15px auto 70px', padding: '0 0 0 5%', background: 'linear-gradient(to bottom, #131414 0%, #131414 47%, #2196f3 47%, #2196f3 52%, #131414 52%, #131414 100%)'}}>
                   <Box sx={{ backgroundColor: '#131414', width: {xs: '32%', sm: '25%', md: '25%', lg: '25%'}, padding: '0 10px'}}>
                     <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#2196f3', fontSize: {xs: '22px', sm: '26px', md: '31px'}, fontWeight: '300', textAlign: 'center'}}>
                       SIGN UP
                     </Typography>
                   </Box>
                 </Box>
                 <Box sx={{ width: '97%', margin: '0 auto', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                   <form onSubmit={handleSubmit}>
                     <Box sx={{ display: 'flex', flexFlow: {xs: 'column nowrap', sm: 'row nowrap'}, justifyContent: 'space-around', margin: '0 auto 50px', width: {xs: '65%', sm: '400px'} }}>
                       <FormControl sx={{ margin: {xs: '0 0 25px', sm: '0'} }}>
                         <InputLabel htmlFor="username">Username</InputLabel>
                         <Input id="construction-username" aria-describedby="my-helper-text" value={username} onChange={(e) => setUsername(e.target.value)}/>
                       </FormControl>
                       <FormControl>
                         <InputLabel htmlFor="password">Password</InputLabel>
                         <Input id="construction-password" aria-describedby="my-helper-text" value={password} type="password" onChange={(e) => setPassword(e.target.value)}/>
                       </FormControl>
                     </Box>
                     <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto 15px' }}>
                       <FormControl>
                         <Button variant="contained" type='submit' sx={{ color: '#131414' }}>Submit</Button>
                       </FormControl>
                     </Box>
                     <Box sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', alignItems: 'center' }}>
                       <FormControl>
                         <Typography variant="p" component="p" className='log-in' sx={{ color: '#cecece', fontSize: {xs: '16px', sm: '16px', md: '16px', lg: '16px'}, fontWeight: '400', padding: '5px' }} onClick={clearInputs}>Cancel</Typography>
                       </FormControl>
                       <FormControl>
                         <Typography variant="p" component="p" className='log-in' sx={{ color: '#cecece', fontSize: {xs: '14px', sm: '14px', md: '14px', lg: '14px'}, fontWeight: '300', padding: '5px' }} onClick={() => setSignPage('signin')}>Sign In Instead</Typography>
                       </FormControl>
                     </Box>
                   </form>
                 </Box>
               </Box>
             </Box>
           </Modal>
         </Box>
         }
       </Box>
    </Box>
  );
}

export default Construction;
