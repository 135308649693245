import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const HeaderModals = ({ openAboutModal, handleAboutClose, openFaqModal, handleFaqClose }) => {

  return(
    <Box>
      <Box>
        <Modal
          open={openAboutModal}
          onClose={handleAboutClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', sm: '80%', md: '70%', lg: '750px'}, maxWidth: '750px', backgroundColor: '#191a1a' }} >
            <Box sx={{  margin: '0 auto', border: '1px solid #ffffffb3', height: {xs: '65vh', sm: '70vh', md: '65vh'}, overflowY: 'scroll', padding: {xs: '20px 20px 15px 20px', sm: '25px 25px 20px 25px', md: '35px 35px 25px 35px'} }}>
              <Box sx={{ width: {xs: '90%', sm: '94%'}, margin: '15px auto 35px', padding: '0 0 0 5%', background: 'linear-gradient(to bottom, #191a1a 0%, #191a1a 47%, #2196f3 47%, #2196f3 52%, #191a1a 52%, #191a1a 100%)'}}>
                <Box sx={{ backgroundColor: '#191a1a', width: {xs: '27%', sm: '22%', md: '19%', lg: '19%'}, padding: '0 10px'}}>
                  <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#2196f3', fontSize: {xs: '24px', sm: '28px', md: '32px'}, fontWeight: '300', textAlign: 'center'}}>
                    ABOUT
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: '97%', margin: '0 auto'}}>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                  How do you choose drumheads?  Most drummers do a Google search for things like 'best snare drum heads', 'best bass drum heads for rock', or something similar.  While that can provide good information, it's only part of the equation.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                  Why?  Because the effectiveness of a drumhead is determined by the head, the drum it's on, and the player that's using it.
                  Heads that work great on one drum might be terrible on another drum, or for another drummer, so it can be tricky to narrow down which heads are the most likely to give you the outcome you're looking for in your particular situation.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                  DrumheadSelector.com is here to help. Our customized recommendation tool uses an exclusive algorithm, based on decades of experience, to help drummers find heads that work.
                  We factor in the specs of drumheads, the specs of your specific drum, your specific playing style, and your preferred sound to come up with the best possible recommendations.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                  Simply put, we want to make selecting drumheads easy, so you don't have to sift through a bunch of conflicting information that doesn't apply to you.
                  Whether you're an experienced drummer or a drummer who's just starting out, we hope this site helps you save time, save money, and helps you sound great.  Thanks for visiting!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>

      <Box>
        <Modal
          open={openFaqModal}
          onClose={handleFaqClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', sm: '80%', md: '70%', lg: '750px'}, maxWidth: '750px', backgroundColor: '#191a1a' }} >
          <Box sx={{  margin: '0 auto', border: '1px solid #ffffffb3', height: {xs: '65vh', sm: '70vh', md: '65vh'}, overflowY: 'scroll', padding: {xs: '20px 20px 15px 20px', sm: '25px 25px 20px 25px', md: '35px 45px 25px 35px', lg: '35px 45px 25px 35px'} }}>
            <Box sx={{ width: {xs: '90%', sm: '94%'}, margin: '15px auto 35px', padding: '0 0 0 5%', background: 'linear-gradient(to bottom, #191a1a 0%, #191a1a 47%, #2196f3 47%, #2196f3 52%, #191a1a 52%, #191a1a 100%)'}}>
              <Box sx={{ backgroundColor: '#191a1a', width: {xs: '15%', sm: '12%', md: '11%', lg: '11%'}, padding: '0 10px'}}>
                <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#2196f3', fontSize: {xs: '24px', sm: '28px', md: '32px'}, fontWeight: '300', textAlign: 'center'}}>
                  FAQ
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '97%', margin: '0 auto', padding: {xs: '5px 0 0', md: '20px 0 0'} }}>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '17px', md: '20px'}, fontWeight: '600', lineHeight: '20px' }}>
                HOW ARE THE CUSTOM RECOMMENDATIONS CALCULATED?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 5, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                We formulated an algorithm that takes into account a drum's specifications, along with the style and preferred sound of the drummer.  Each are important to varying degrees.  The resulting list should be a range of the best possible heads for that specific circumstance.
                Formulating a process that produces quality results is made possible by the practical and technical drumming experience of both the developer and the other players that were consulted.  We're always fine-tuning, so it should only improve.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '17px', md: '20px'}, fontWeight: '600', lineHeight: '20px' }}>
                HOW DO YOU CHOOSE THE ALL-PURPOSE RECOMMENDATIONS?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                All-purpose heads need to be versatile.  They have to be thin enough to hear nuances at lower volumes, thick enough to handle fairly-hard hitting, have a wide tuning range, and work on all types of drums, from student level to professional.
                There are no heads that do all of those things perfectly, but some heads do them all well.
                If you play a wide range of genres, the all-purpose recommendations should work for you.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
              We find that for toms, the range from the thick side of single-ply heads (12-mil) to the thin side of double-ply heads (14-mil) work well in most situations.  For bass drums, medium to thick single-ply heads (10-mil to 12-mil) with built-in dampening are typically very versatile.  If you have a larger bass drum or larger toms, tending towards the thick side of that range is probably best.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
              Snares often need a bit more articulation, so we believe that heads in the range between standard 10-mil single-ply (with a dot) to thicker 12-mil and 14-mil single-ply (without a dot or internal dampening) provide a good balance of sensitivity and durablity. For extra durablity and warmth, standard double-ply heads can also be quite versatile.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 5, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
              Of course, keep in mind the possibility of using external dampening, such as gels or a bass drum pillow, which can help you fine-tune your sound.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '17px', md: '20px'}, fontWeight: '600', lineHeight: '20px' }}>
                WHY DO YOU INCLUDE REMO AND EVANS?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 5, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                We currently list Remo and Evans for several reasons.  They're the two most popular drumhead brands, they offer a full range of sounds, they're readily available at most music seller sites, and we believe their quality is very high.
                In the future, we may add Aquarian to the mix, as well.  For now, however, we felt it necessary to streamline the site to include two brands.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#ffffffb3', fontSize: {xs: '16px', sm: '17px', md: '20px'}, fontWeight: '600', lineHeight: '20px' }}>
                COULD ALL REMO AND EVANS HEAD MODELS BE INCLUDED IN THE CUSTOM RECOMMENDATIONS?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1, mb: 4, color: '#ffffffb3', fontSize: {xs: '14px', sm: '15px', md: '16px' } }}>
                No, we choose from a list of heads that have at least a baseline level of availability, popularity, and uniqueness of sound.
                If we used every Remo and Evans model, the resulting recommendations would often be unavailable and/or nearly identical.  Our aim is to use a wide variety of heads that result in slight variety, which are typically available from popular sellers.
                Between snare, toms, and bass drum, there are currently over 100 head models used in the calculation.
              </Typography>
            </Box>
          </Box>
        </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default HeaderModals;
