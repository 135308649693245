import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import StorePopover from './StorePopover';
import HeaderModals from './HeaderModals';
import logo from '../../assets/dhsLogo.png';
import { useState } from 'react';

const pages = ['ABOUT', 'FAQ'];

const Header = ( { storeSelected, changeStore, toSelectorShowing }) => {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFaqModal, setOpenFaqModal] = useState(false);
  const [openAboutModal, setOpenAboutModal] = useState(false);
  const handleFaqClose = () => setOpenFaqModal(false);
  const handleAboutClose = () => setOpenAboutModal(false);

// opening/closing hamburger menu
  const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
        setAnchorElNav(null);
      if(page === 'ABOUT') {
        setOpenAboutModal(true);
      } else if (page === 'FAQ') {
        setOpenFaqModal(true);
      } else {
        return;
      }
    };

  const openStoreIcon = (event) => {
        setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return(
    <Box id='header' sx={{ margin: {xs: '5px auto 0px', md: '10px auto 0', lg: '10px auto 0'}, maxWidth: '1300px' }}>
      <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', height: {xs: '60px', sm: '70px'} }} className='AppBar'>
        <Container maxWidth="xl" sx={{ paddingRight: {xs: '15px', sm: '12px', md: '24px'} }}>
          <Toolbar disableGutters>
            <Box
              component="div"
              sx={{ margin: '0 22px 0 0', display: { xs: 'none', md: 'flex' } }}
            >
              <Box sx={{ width: '150px'}}>
                <img src={logo} alt='logo' className='logo'/>
              </Box>
            </Box>
            {
              //hamburger icon
            }
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: '#ffffffb3'}}
              >
                <MenuIcon />
              </IconButton>
              {
                //when dropdown menu showing
              }
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu('close')}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)} >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <Box sx={{ width: {xs: '110px', sm: '130px', md: '150px'}, margin: {sm: '0 0 0 30px' }  }}>
                {
                  // <img src={logo} alt='logo' className='logo'/>
                }
              </Box>
            </Box>

            {
              //full width menu buttons
            }
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{ marginRight: '15px', my: 2, color: '#ffffffb3', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <StorePopover changeStore={changeStore} storeSelected={storeSelected} openStoreIcon={openStoreIcon} handleClose={handleClose} id={id} open={open} anchorEl={anchorEl}/>
            <HeaderModals openAboutModal={openAboutModal} handleAboutClose={handleAboutClose} openFaqModal={openFaqModal} handleFaqClose={handleFaqClose}/>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default Header;
