import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState } from 'react';

const styleMarks = [
  {
    value: 1,
    label: 'Light',
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: 'Medium',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: 'Heavy',
  }
];

const darkBrightMarks = [

  {
    value: 1,
    label: 'Dark',
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: 'Medium',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: 'Bright',
  }
];

const dryResonantMarks = [

  {
    value: 1,
    label: 'Dry',
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: 'Medium',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: 'Resonant',
  }
];

const DrumStyle = ({ setFullyBuilt, updateStyleSound, setSpecsFull, setSpecsToSend, styleValues, setStyleValues }) => {

    // const [fullyStyled, setFullyStyled] = useState(true);

    const handleStyleChange = (event, newValue) => {
      // console.log('style: ', newValue);
      setStyleValues({...styleValues, style: newValue});
    }

    const handleDarkBrightChange = (event, newValue) => {
      // console.log('sound: ', newValue);
      setStyleValues({...styleValues, darkBright: newValue});
    }

    const handleDryResonantChange = (event, newValue) => {
      // console.log('sound: ', newValue);
      setStyleValues({...styleValues, dryResonant: newValue});
    }

    const [openStyleModal, setOpenStyleModal] = useState(false);
    const handleStyleOpen = () => setOpenStyleModal(true);
    const handleStyleClose = () => setOpenStyleModal(false);

    const [openSoundModal, setOpenSoundModal] = useState(false);
    const handleSoundOpen = () => setOpenSoundModal(true);
    const handleSoundClose = () => setOpenSoundModal(false);

    const startOver = (e) => {
      setSpecsFull(false);
      setFullyBuilt(false);
      setSpecsToSend({});
      setStyleValues({
          style: 3,
          darkBright: 3,
          dryResonant: 3
        });
      }

      const scrollToRecs = () => {
        const recsRef = document.getElementById('recsRef');
        recsRef.scrollIntoView({ behavior: 'smooth' });
        // turnOffButton();
      }

  return(

    <Box sx={{ height: {xs: '520px', sm: '535px', md: '450px', lg: '450px'}, maxWidth: {xs: '700px', lg: '800px'}, width: { xs: '90%', sm: '90%', md: '85%'}, padding: {xs: '3px 10px 10px 10px', sm: '10px'}, margin: '0 auto' }}>
      <Box sx={{ height: {xs: '415px', md: '350px'} }}>
        <Box sx={{ flexGrow: 1, margin: {xs: '0 10px 15px', sm: '0 10px 15px', md: '0 10px 15px'} }}>
          <Typography variant="h4" component="h4" color="#ffffffb3" sx={{ display: 'inline', fontSize: '20px', fontWeight: '500', padding: '5px'}}>
            SELECT YOUR STYLE
          </Typography>
          <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleStyleOpen}/>
        </Box>
        <Modal
          open={openStyleModal}
          onClose={handleStyleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-style' sx={{ backgroundColor: '#eceff1'}}>
            <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'} }}>
              <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500'}}>
                STYLE HELP
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                Select the volume/aggressiveness you generally play with.  If you play a wide variety of volumes and styles, choose within the medium range.  Results will take into account head durability and effectiveness at various volumes.
              </Typography>
            </Box>
          </Box>
        </Modal>

        <Grid sx={{ justifyContent: 'center', mb: 5 }} container columnSpacing={2} rowSpacing={{xs: '29px', sm: '32px'}} className='drum-spec-grid-container'>
          <Grid item xs={1} sm={1} md={4}>
          </Grid>
          <Grid item xs={10} sm={8} md={4} >
            <FormControl fullWidth sx={{maxWidth: {xs: '275px', sm: '350px'} }}>
              <Typography sx={{fontSize: '14px'}} className='tom-range'></Typography>
              <Slider
                track={false}
                aria-label="Style"
                defaultValue={styleValues.style}
                value={styleValues.style}
                valueLabelDisplay="off"
                onChange={handleStyleChange}
                step={1}
                marks={styleMarks}
                min={0.9}
                max={5.1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={4} >
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1, margin: {xs: '60px 10px 30px', sm: '64px 10px 30px', md: '73px 10px 15px'} }}>
          <Typography variant="h4" component="h4" color="#ffffffb3" sx={{ display: 'inline', fontSize: '20px', fontWeight: '500', padding: '5px'}}>
            SELECT PREFERRED SOUND
          </Typography>
          <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleSoundOpen}/>
        </Box>
        <Modal
          open={openSoundModal}
          onClose={handleSoundClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-style' sx={{ backgroundColor: '#eceff1'}}>
            <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'} }}>
              <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500'}}>
                SOUND HELP
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                Sound is somewhat subjective, but these descriptions may help you determine how we describe various terms.  Keep in mind that there are pros and cons to every head.  Some sound combinations (particularily dark/resonant and bright/dry) can be more difficult to achieve.  Careful tuning and/or external dampening can often help get the sound closer to the desired effect, however.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                DARK & BRIGHT
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                These terms refer to the prevalant frequencies produced by the drum.  A darker tone accentuates lower frequencies, tends to 'cut' less, and has less articulation, but it also has a warmth that many drummers like. Darker heads are usually thicker and have a lower tuning range.  A brighter tone accentuates higher frequencies, tends to be more articulate and easily heard, but also has less tonal warmth.  Bright heads are usually a bit thinner and have a higher tuning range.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                DRY & RESONANT
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                These terms refer to the amount of sustain each note produces.  A dry sound has a shorter sustain and is typically more articulate, while a more resonant sound sustains for a longer time and has more fullness.  Most drummers use dampening products on their drums, allowing them to fine-tune the amount of sustain somewhat.
              </Typography>
            </Box>
          </Box>
        </Modal>

        <Grid sx={{ justifyContent: 'center', mb: {xs: '40px', sm: '40px', md: '64px'} }} container columnSpacing={2} rowSpacing={{xs: '29px', sm: '32px'}} className='drum-spec-grid-container'>
          <Grid item xs={0} sm={0} md={0} lg={0}>
          </Grid>
          <Grid item xs={10} sm={10} md={4} lg={4}>
            <FormControl fullWidth sx={{maxWidth: {xs: '275px', sm: '350px'} }}>
              <Typography sx={{fontSize: '14px'}} className='tom-range'></Typography>
              <Slider
                track={false}
                aria-label="Dark/Bright"
                defaultValue={styleValues.darkBright}
                value={styleValues.darkBright}
                valueLabelDisplay="off"
                onChange={handleDarkBrightChange}
                step={1}
                marks={darkBrightMarks}
                min={0.9}
                max={5.1}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={1} lg={1}>
          </Grid>
          <Grid item xs={10} sm={10} md={4} lg={4}>
            <FormControl fullWidth sx={{maxWidth: {xs: '275px', sm: '350px'} }}>
              <Typography sx={{fontSize: '14px'}} className='tom-range'></Typography>
              <Slider
                track={false}
                aria-label="Dry/Resonant"
                defaultValue={styleValues.dryResonant}
                value={styleValues.dryResonant}
                valueLabelDisplay="off"
                onChange={handleDryResonantChange}
                step={1}
                marks={dryResonantMarks}
                min={0.9}
                max={5.1}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box >
        <Button variant="contained" onClick={()=> updateStyleSound(styleValues)} sx={{  margin: '0 auto' }}>
          Get Results
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignSelf: 'center'}}>
        <Box sx={{ width: '30px', height: '30px'}}>
        </Box>
        <Box>
          <Typography id="modal-modal-description" sx={{ margin: {xs: '10px 0 12px', sm: '16px 0'} }} className="start-over" onClick={startOver}>
            start over
          </Typography>
        </Box>
        <Box sx={{ width: '30px', height: '30px'}}>
          <Box sx={{ display: {xs: 'block', md: 'block' }}} >
            <ArrowDownwardIcon className='down-arrow-icon' sx={{ alignSelf: 'start', fontSize: {xs: '24px', md: '28px', lg: '30px'} }} onClick={scrollToRecs}/>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default DrumStyle;
