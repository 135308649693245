import Box from '@mui/material/Box';

const Buffer = () => {
  return(
    <Box sx={{ height: '5px', width: '50%', margin: '0 auto' }} >

    </Box>
  );
}

export default Buffer;
