import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import HelpPopover from '../../popups/HelpPopover';
import { useState } from 'react';
import './resultsCarousel.css';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const eightInch = '8"'
const tenInch = '10"';
const twelveInch = '12"';
const thirteenInch = '13"';
const fourteenInch = '14"';
const fifteenInch = '15"';
const sixteenInch = '16"';
const eighteenInch = '18"';
const twentyInch = '20"';
const twentyTwoInch = '22"';
const twentyFourInch = '24"';
const twentySixInch = '26"';

const ColorButton = styled(Button)(({ theme }) => ({
  color: grey[400],
  backgroundColor: grey[500],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: grey[500],
    boxShadow: 'none'
  },
}));


const ResultsCarouselBottom = ({ head, type, storeSelected }) => {

  const [size, setSize] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [defaultShowing, setDefaultShowing] = useState(true);
  const [tomSizeSelected, setTomSizeSelected] = useState(false);
  const [buttonLinks, setButtonLinks] = useState(
    {
      id: head.id,
      buyLink: '',
      amazonBuyLink: head.amazonBuyLinks[head.sizes.indexOf(head.specificSize)],
      guitarCenterBuyLink: head.gcBuyLinks[head.sizes.indexOf(head.specificSize)],
      sweetwaterBuyLink: head.swBuyLinks[head.sizes.indexOf(head.specificSize)]
    }
  );

  const openBuyLink = (id) => {
    if(defaultShowing && head.specificSize) {
      let sizeSelected = head.specificSize;
      // setSize(sizeSelected);

      let match = head.sizes.indexOf(parseInt(sizeSelected));
      // let store = storeSelected;
      // console.log(head.sizes);
      // console.log(head.amazonBuyLinks[match]);
        if(storeSelected === 'amazon') {
              // console.log(buttonLinks.amazonBuyLink);
              window.open(
                head.amazonBuyLinks[match], "_blank");
        } else if (storeSelected === 'guitar center') {
              // console.log(buttonLinks.guitarCenterBuyLink);
              window.open(
                head.gcBuyLinks[match], "_blank");
        } else {
              // console.log(buttonLinks.sweetwaterBuyLink);
              window.open(
                head.swBuyLinks[match], "_blank");
            }
        } else {
        // console.log(buttonLinks);
        if(storeSelected === 'amazon') {
              // console.log(buttonLinks.amazonBuyLink);
              window.open(
                buttonLinks.amazonBuyLink, "_blank");
        } else if (storeSelected === 'guitar center') {
              // console.log(buttonLinks.guitarCenterBuyLink);
              window.open(
                buttonLinks.guitarCenterBuyLink, "_blank");
        } else {
              // console.log(buttonLinks.sweetwaterBuyLink);
              window.open(
                buttonLinks.sweetwaterBuyLink, "_blank");
            }
      }

    };

  const changeLink = (id, amazonLink, gcLink, swLink) => {
    setButtonLinks( {amazonBuyLink: amazonLink, guitarCenterBuyLink: gcLink, sweetwaterBuyLink: swLink});
    // console.log('button links', buttonLinks);
  }

  const handleChange = (id, value) => {
    setDefaultShowing(false);
    if(type === 'TOMS' && !tomSizeSelected) {
      setTomSizeSelected(true)
    }
      setSize(value);
      setButtonEnabled(true);
      let match = head.sizes.indexOf(parseInt(value));
      // console.log(head.sizes);
      // console.log(head.amazonBuyLinks[match]);
      changeLink(id, head.amazonBuyLinks[match], head.gcBuyLinks[match], head.swBuyLinks[match]);
  }

  return(
    <Box sx={{ margin: {xs: '10px auto 10px', sm: '10px auto 10px', md: '10px auto 10px' }, height: {xs: 'auto', md: '55px'}, display: 'flex', flexFlow: {xs: 'column nowrap', md: 'row nowrap'}, justifyContent: 'center', alignItems: 'center' }}>
      {
        type === 'SNARE' ?

        <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
          <InputLabel id="demo-simple-select-label">Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={
                head.specificSize !== '' && defaultShowing ?
                head.specificSize
                :
                (
                  size
                )
              }
            label="Size"
            onChange={(e) => handleChange(head.id, e.target.value)}
          >
            <MenuItem value={13}>{thirteenInch}</MenuItem>
            <MenuItem value={14}>{fourteenInch}</MenuItem>
          </Select>
        </FormControl>
        :
        (
          type === 'TOMS' ?
        <FormControl sx={{  maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
          <InputLabel id="demo-simple-select-label">Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={
              size
            }
            label="Size"
            onChange={(e) => handleChange(head.id, e.target.value)}
          >
            <MenuItem value={8}>{eightInch}</MenuItem>
            <MenuItem value={10}>{tenInch}</MenuItem>
            <MenuItem value={12}>{twelveInch}</MenuItem>
            <MenuItem value={13}>{thirteenInch}</MenuItem>
            <MenuItem value={14}>{fourteenInch}</MenuItem>
            <MenuItem value={15}>{fifteenInch}</MenuItem>
            <MenuItem value={16}>{sixteenInch}</MenuItem>
            <MenuItem value={18}>{eighteenInch}</MenuItem>
          </Select>
        </FormControl>
          :
          <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
            <InputLabel id="demo-simple-select-label">Size</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                head.specificSize !== '' && defaultShowing ?
                head.specificSize
                :
                (
                  size
                )
              }
              label="Size"
              onChange={(e) => handleChange(head.id, e.target.value)}
            >
              <MenuItem value={18}>{eighteenInch}</MenuItem>
              <MenuItem value={20}>{twentyInch}</MenuItem>
              <MenuItem value={22}>{twentyTwoInch}</MenuItem>
              <MenuItem value={24}>{twentyFourInch}</MenuItem>
              <MenuItem value={26}>{twentySixInch}</MenuItem>
            </Select>
          </FormControl>
          )
        }

        {
          (head.specificSize === 'none' && !tomSizeSelected) || (!buttonEnabled && type === 'TOMS' ) ?
          <ColorButton variant="contained" sx={{ margin: {xs: '10px 20px 20px', sm: '10px 20px 5px', md: '10px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '12px 16px 13px', sm: '12px 16px 13px', md: '11px 10px', lg: '11px 10px'}, lineHeight: {xs: '1.3em', sm: '1.3em', md: '1.3em', lg: '1.3em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'} }}>Choose Size</ColorButton>
          :
          (
            storeSelected === 'amazon' ?
            (
              buttonLinks.amazonBuyLink === 'none' || (head.amazonBuyLinks[head.sizes.indexOf(parseInt(head.specificSize))] === 'none' && defaultShowing) ?
                <Box>
                  <HelpPopover storeSelected={storeSelected} />
                </Box>
                :
                <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                  <Box>See on</Box>
                  <Box>Amazon</Box>
                </Button>
            )
            :
            (
              storeSelected === 'guitar center' ?
              (
               buttonLinks.guitarCenterBuyLink === 'none' ?
                 <Box>
                   <HelpPopover storeSelected={storeSelected} />
                 </Box>
                :
                <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 20px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                  <Box>See on</Box>
                  <Box>Guitar Center</Box>
                </Button>
               )
                :
                buttonLinks.sweetwaterBuyLink === 'none' ?
                  <Box>
                    <HelpPopover storeSelected={storeSelected} />
                  </Box>
                  :
                  <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 20px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                    <Box>See on</Box>
                    <Box>Sweetwater</Box>
                  </Button>
              )
            )
        }

    </Box>
  );
}

export default ResultsCarouselBottom;
