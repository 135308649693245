import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: grey[400],
  backgroundColor: grey[500],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: grey[500],
    boxShadow: 'none'
  },
}));

const HelpPopover = ({ storeSelected }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  let storeName = '';
  storeSelected === 'amazon' ? storeName = 'Amazon' : (storeSelected === 'guitar center' ? storeName = 'Guitar Center' : storeName = 'Sweetwater');

  const openHelpIcon = (event) => {
      setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return(
    <Box sx={{ position: 'relative' }} >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        >
        <Box sx={{ }}>
          <Typography sx={{ padding: '15px 15px 5px', maxWidth: '175px', fontSize: '13px', textAlign: 'center', color: '#eceff1' }}>This size/model is currently not listed on {storeName}.</Typography>
          <Typography sx={{ padding: '5px 15px 15px', maxWidth: '145px', fontSize: '13px', textAlign: 'center', margin: '0 auto', color: '#eceff1' }}>Click the store icon to check other sites.</Typography>
        </Box>
       </Popover>
       <ColorButton variant="contained" disabled sx={{ backgroundColor: "#303236", fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 15px', sm: '10px 20px 20px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', justifyContent: 'center', flexFlow: 'column nowrap' }}>
         <Box>Not on</Box>
         <Box>{storeName}</Box>
       </ColorButton>
       <HelpIcon sx={{ position: 'absolute', right: {xs: '4px', md: '-8px'}, top: '-14px'  }} fontSize="medium" color="#f2f2f2" className='help-icon' onClick={openHelpIcon}/>
     </Box>
  );
}

export default HelpPopover;
