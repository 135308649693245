import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, useEffect } from 'react';

const BassSpecs = ({ updateSpecs, fullyBuilt, setFullyBuilt }) => {

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [bassBuild, setBassBuild] = useState({
      diameter: null,
      depth: null,
      shell: null,
      lugs: null,
      mounts: null,
      quality: null,
      era: null
    });

    // loop through bassBuild to see if all properies have values
    // if so, the next button is enabled
    let nullCount = 0;

    const checkBassBuild = () => {
      for(const prop in bassBuild) {
        // console.log(bassBuild[prop]);
        if(bassBuild[prop] !== null) {
          nullCount += 1;
          if(nullCount === 7) {
            // console.log('7'');
            setFullyBuilt(true);
          } else {
            // console.log('nulls: ', nullCount);
          }
        }
      }
    }

    useEffect(() => {
      // console.log('useEffect ', bassBuild);
      checkBassBuild();
      // console.log(nullCount);
    });

    const recsRef = document.getElementById('recsRef');
    const scrollToRecs = () => {
      recsRef.scrollIntoView({ behavior: 'smooth' });
    };


    const [diameter, setDiameter] = useState('');
    const handleDiameterChange = (event) => {
      let diameterValue = event.target.value;
      setDiameter(diameterValue);
      setBassBuild({...bassBuild, diameter: diameterValue});
    };

    const [depth, setDepth] = useState('');
    const handleDepthChange = (event) => {
      let depthValue = event.target.value;
      setDepth(depthValue);
      setBassBuild({...bassBuild, depth: depthValue});
    };

    const [shell, setShell] = useState('');
    const handleShellChange = (event) => {
      let shellValue = event.target.value;
      setShell(shellValue);
      setBassBuild({...bassBuild, shell: shellValue});
    };

    const [lugs, setLugs] = useState('');
    const handleLugsChange = (event) => {
      let lugsValue = event.target.value;
      setLugs(lugsValue);
      setBassBuild({...bassBuild, lugs: lugsValue});
    };

    const [mounts, setMounts] = useState('');
    const handleMountsChange = (event) => {
      let mountsValue = event.target.value;
      setMounts(mountsValue);
      setBassBuild({...bassBuild, mounts: mountsValue});
    };

    const [quality, setQuality] = useState('');
    const handleQualityChange = (event) => {
      let qualityValue = event.target.value;
      setQuality(qualityValue);
      setBassBuild({...bassBuild, quality: qualityValue});
    };

    const [era, setEra] = useState('');
    const handleEraChange = (event) => {
      let eraValue = event.target.value;
      setEra(eraValue);
      setBassBuild({...bassBuild, era: eraValue});
    };

//resets all input values
    const startOver = (e) => {
      setFullyBuilt(false);
      nullCount = 0;
        setBassBuild({
          diameter: null,
          depth: null,
          shell: null,
          lugs: null,
          mounts: null,
          quality: null,
          era: null
        });
        setDiameter('');
        setDepth('');
        setShell('');
        setLugs('');
        setMounts('');
        setQuality('');
        setEra('');
 }

  return(

    <Box sx={{ height: {xs: '520px', sm: '535px', md: '450px', lg: '450px'}, maxWidth: {xs: '550px', lg: '800px'}, width: { xs: '90%', sm: '90%', md: '85%'}, padding: {xs: '3px 10px 10px 10px', sm: '10px'}, margin: '0 auto' }}>
      <Box sx={{ height: {xs: '415px', sm: '435px', md: '350px'} }}>
        <Box sx={{ flexGrow: 1, margin: {xs: '0 10px 38px', sm: '0 10px 35px'} }}>
          <Typography variant="h4" component="h4" color="#ffffffb3" sx={{ display: 'inline', fontSize: '20px', fontWeight: '500', padding: '5px'}}>
            SELECT YOUR SPECS
          </Typography>
          <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleOpen}/>
        </Box>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-style' sx={{ backgroundColor: '#eceff1'}}>
            <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'} }}>
              <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500' }}>
                BASS DRUM SPEC HELP
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                If you don't know or can't find out certain specifications, estimate with the information below.  If an educated guess is made with one or two of the specs, the result should still generate good results.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                DIAMETER
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                A drum's diameter is measured across the head, from the inside of the hoop on one side to the other.  The hoops are not included in this measurement.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                DEPTH
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                A drum's depth measures the shell, not including the hoops.  Measure to and from where the drumheads contact the shell.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                SHELL MATERIAL
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                The shell material is often made clear by the model name or it can be found with a simple Google search.  For hybrid shells, select the most prominent wood.  If you have an unknown student drum set, it is likely either made of basswood or poplar.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                LUG COUNT
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                Lugs are counted per side.  Count the number of tension rods used to tighten each head.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                TOM MOUNTING
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                Rack toms can be mounted to the top of the bass drum with a tom arm or can be mounted on a separate stand.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                QUALITY LEVEL
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                As a rough estimation, budget drum sets typically sell new with hardware and cymbals for between $300 - $900.  Semi-pro kits are usually sold as shell packs (no hardware or cymbals), although not always, and are typically in the $600 - $1300 range.  New professional drum sets are almost always sold as shell packs and typically sell for at least $1300 and up.
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                ERA
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                If you don't know the approximate date of a drum, some research may need to be done.  If you can't determine its age, make an educated guess based on drum badges, colors, etc.
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Grid sx={{ justifyContent: 'center', mb: {xs: '26px', sm: '40px'} }} container columnSpacing={2} rowSpacing={{xs: '29px', sm: '32px'}} >
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Diameter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={diameter}
                  label="Diameter"
                  onChange={handleDiameterChange}
                  >
                <MenuItem value={18}>18"</MenuItem>
                <MenuItem value={20}>20"</MenuItem>
                <MenuItem value={22}>22"</MenuItem>
                <MenuItem value={24}>24"</MenuItem>
                <MenuItem value={26}>26"</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Depth</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={depth}
                  label="Depth"
                  onChange={handleDepthChange}
                  >
                <MenuItem value={14}>14"</MenuItem>
                <MenuItem value={16}>16"</MenuItem>
                <MenuItem value={18}>18"</MenuItem>
                <MenuItem value={20}>20"+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Shell Material</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={shell}
                  label="Shell Material"
                  onChange={handleShellChange}
                  >
                <MenuItem value={'acrylic'}>Acrylic</MenuItem>
                <MenuItem value={'basswood'}>Basswood</MenuItem>
                <MenuItem value={'birch'}>Birch</MenuItem>
                <MenuItem value={'mahogany'}>Mahogany</MenuItem>
                <MenuItem value={'maple'}>Maple</MenuItem>
                <MenuItem value={'oak'}>Oak</MenuItem>
                <MenuItem value={'poplar'}>Poplar</MenuItem>
                <MenuItem value={'other'}>Other/Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Lug Count</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lugs}
                  label="Lug Count"
                  onChange={handleLugsChange}
                  >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tom Mounting</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mounts}
                  label="Tom Mounting"
                  onChange={handleMountsChange}
                  >
                <MenuItem value={'with-mount'}><Typography>Bass Drum Mounted</Typography></MenuItem>
                <MenuItem value={'no-mount'}>No Bass Drum Mount</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quality Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quality}
                  label="Quality Level"
                  onChange={handleQualityChange}
                  >
                  <MenuItem value={'budget'}>Budget</MenuItem>
                  <MenuItem value={'semi-pro'}>Semi-Pro</MenuItem>
                  <MenuItem value={'professional'}>Professional</MenuItem>
                  <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Era</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={era}
                  label="Era"
                  onChange={handleEraChange}
                  >
                <MenuItem value={'pre-1970s'}>Pre-1970s</MenuItem>
                <MenuItem value={'1970s'}>1970s</MenuItem>
                <MenuItem value={'1980s'}>1980s</MenuItem>
                <MenuItem value={'1990s'}>1990s</MenuItem>
                <MenuItem value={'2000s'}>2000s</MenuItem>
                <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {
        fullyBuilt ?
        <Button variant="contained" onClick={() => updateSpecs(bassBuild)}>
          Next
        </Button>
        :
        <Tooltip title="Fill out all specs" placement="top">
        <span>
          <Button variant="contained" disabled >
            Next
          </Button>
          </span>
        </Tooltip>
      }
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignSelf: 'center'}}>
        <Box sx={{ width: '30px', height: '30px'}}>
        </Box>
        <Box>
          <Typography id="modal-modal-description" sx={{ margin: {xs: '10px 0 12px', sm: '16px 0'} }} className="start-over" onClick={startOver}>
            start over
          </Typography>
        </Box>
        <Box sx={{ width: '30px', height: '30px'}}>
          <Box sx={{ display: {xs: 'block', md: 'block' }}} >
            <ArrowDownwardIcon fontSize="medium" className='down-arrow-icon' sx={{ alignSelf: 'start', fontSize: {xs: '24px', md: '28px', lg: '30px'} }} onClick={() => scrollToRecs()}/>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default BassSpecs;
