import Header from '../header/Header';
import Main from '../main/Main';
import Box from '@mui/material/Box';

const FirstScreen = ({ storeSelected, changeStore }) => {




  return (
    <Box sx={{ minHeight: '100svh' }}>
      <Header storeSelected={storeSelected} changeStore={changeStore} />
      <Main/>
    </Box>
  );
}

export default FirstScreen;
