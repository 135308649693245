import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const DrumType = ({ drumType, switchDrumType }) => {

  return (
    <Box sx={{ width: '280px', margin: {xs: '0 auto 25px', sm: '20px auto 30px', md: '0 auto 40px'}, padding: {xs: '0.5vh 0 0', sm: '25px 0 0', md: '40px 0 0', lg: '50px 0 0'} }}>
      <Tabs
        value={drumType}
        onChange={switchDrumType}
        textColor="primary"
        indicatorColor="primary"
        aria-label="drum type"
      >
        <Tab value="snare" label="Snare" sx={{fontSize: '16px'}}/>
        <Tab value="toms" label="Toms" sx={{fontSize: '16px'}}/>
        <Tab value="bass" label="Bass" sx={{fontSize: '16px'}}/>
      </Tabs>
    </Box>
  );
}

export default DrumType;
