import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import BottomHeadsBottom from './BottomHeadsBottom';

const BottomSnareTom = ({ type, headData, storeSelected, sizeSelected, setSizeSelected }) => {

  return(
    <Box sx={{ maxWidth: '1100px', padding: {md: '0'}  }}>
        {
          type === 'SNARE RESO' ?
          <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '70px', sm: '80px', md: '70px', lg: '70px'}, fontSize: {xs: '110px', sm: '175px', md: '260px', lg: '335px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
            SNARE
          </Typography>
          :
          (
            type === 'TOMS RESO' ?
            <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '65px', sm: '80px', md: '80px', lg: '80px'}, fontSize: {xs: '120px', sm: '195px', md: '290px', lg: '365px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
              TOMS
            </Typography>
            :
            <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '80px', sm: '80px', md: '80px', lg: '80px'}, fontSize: {xs: '110px', sm: '195px', md: '300px', lg: '380px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
              {type}
            </Typography>
          )
        }

      <Box sx={{ minHeight: '375px', margin: {xs: '25px 5px 5px', sm: '20px 5px 10px', md: '40px 5px 20px'}, padding: '5px', display: 'flex', flexFlow: 'row nowrap'}} className='bottomSnareDiv'>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-around', width: '98%', margin: '0 auto'}}>
          {
            headData.map(head => {
              return(
                <Box key={head.id} sx={{ width: { xs: '180px', sm: '270px', md: '355px', lg: '390px'}, height: {xs: '440px', sm: '500px', md: '525px', lg: '555px'}, zIndex: '2', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'flex-end'}} className={`bottom-head-background ${type} ${head.brand}`}>
                  <Card sx={{ width: { xs: '180px', sm: '300px', md: '300px', lg: '300px'}, margin: '0 auto' }}>
                    <CardHeader
                      sx={{ color: '#eceff1', padding: {xs: '5px 12px 5px', sm: '5px 12px 5px', md: '5px 12px 5px', lg: '5px 16px 5px'} }}
                      title={<Typography className='cardGradient' sx={{color: '#2196f3', fontSize: {xs: '30px', sm: '30px', md: '30px'}, lineHeight: {xs: '35px'}, fontWeight: '600'}}>{head.brand}</Typography>}
                      subheader={<Typography sx={{ color: '#2196f3', fontSize: {md: '14px', lg: '16px'} }} className='cardGradient'>{head.model}</Typography>}
                    />
                    <CardContent sx={{ padding: { xs: '7px 10px 15px', md: '5px 12px 5px', lg: '5px 16px 5px'} }}>
                      <Box sx={{ display: 'flex', flexFlow: {xs: 'column nowrap', sm: 'row nowrap'}, justifyContent: 'center'}}>
                        <Typography variant="body2" className='cardLight' sx={{ fontSize: {xs: '14px', sm: '14px', md: '14px'}, margin: '0 7px' }}>
                          &#183; {head.spec1}
                        </Typography>
                        {
                          head.spec2 !== '' ?
                          <Box>
                            <Typography variant="body2" className='cardLight' sx={{ fontSize: {xs: '14px', sm: '14px', md: '14px'}, margin: '0 7px' }}>
                              &#183; {head.spec2}
                            </Typography>
                          </Box>
                          :
                          <Box></Box>
                        }
                      </Box>
                    </CardContent>
                    <Box>
                      <BottomHeadsBottom head={head} type={type} storeSelected={storeSelected} sizeSelected={sizeSelected} setSizeSelected={setSizeSelected} />
                    </Box>
                  </Card>
                </Box>
              )
            })
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        </Box>
      </Box>
    </Box>
  );
}

export default BottomSnareTom;
