import Construction from './components/construction/Construction';
import FirstScreen from './components/firstScreen/FirstScreen';
import Main from './components/main/Main';
import Recommendations from './components/recommendations/Recommendations';
import BottomHeads from './components/bottomHeads/BottomHeads';
import QuickTips from './components/quickTips/QuickTips';
import Featured from './components/featured/Featured';
import About from './components/about/About';
import Affiliate from './components/quickTips/Affiliate';
import ScrollTo from './components/scrollTo/ScrollTo';
import Buffer from './components/buffer/Buffer';
import Footer from './components/footer/Footer';
import Selector from './components/selector/Selector';
import Box from '@mui/material/Box';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import theme from './components/theme.js';
import './App.css';
// ! temporary bug fix in @mui/material/Grow/Grow.js --- change back after bug resolved
const App = () => {

  const [signedIn, setSignedIn] = useState(true);
  const [storeSelected, setStoreSelected] = useState('amazon');
  const [initialLoad, setInitialLoad] = useState(false);

  const changeStore = (store) => {
    setStoreSelected(store);
  }

  useEffect(() => {
    if(!initialLoad && signedIn) {
      window.scrollTo(0, 0);
      // setToSelectorShowing(true);
      setInitialLoad(true);
    } else {
      return;
    }
  }, [initialLoad, signedIn]);


  return(
    <ThemeProvider theme={theme}>
      {
        signedIn ?
        <Box className='App'>
          <FirstScreen storeSelected={storeSelected} changeStore={changeStore} />
          <Buffer />
          <ScrollTo />
          <Selector storeSelected={storeSelected} changeStore={changeStore} />
          <Recommendations storeSelected={storeSelected} changeStore={changeStore} />
          <BottomHeads storeSelected={storeSelected} changeStore={changeStore}/>
          <QuickTips storeSelected={storeSelected}/>
          <Featured storeSelected={storeSelected} changeStore={changeStore} />
          <About />
          <Affiliate />
          <Footer />
        </Box>
        :
        <Box className='App-construction' id='Construction'>
          <Construction signedIn={signedIn} setSignedIn={setSignedIn}/>
        </Box>
      }
    </ThemeProvider>
  );
}

export default App;
