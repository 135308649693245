import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const AffiliateModal = ({ openAffiliate, setOpenAffiliate, handleAffiliateClose }) => {

  return(
    <Box>
      <Box>
        <Modal
          open={openAffiliate}
          onClose={handleAffiliateClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-style' sx={{ backgroundColor: '#eceff1' }}>
            <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'}, padding: '5px 0' }}>

              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                DrumheadSelector.com may receive a small commission for sales generated through Amazon product links.  Of course, your price doesn't increase at all!
              </Typography>

              <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  Thanks so much for your support!
              </Typography>

            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default AffiliateModal;
