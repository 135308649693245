import DrumStyle from '../drumStyle/DrumStyle';
import SnareSpecs from './SnareSpecs';
import Box from '@mui/material/Box';

const SnareSelector = ({ specsFull, setSpecsFull, updateSpecs, fullyBuilt, setFullyBuilt, updateStyleSound, setSpecsToSend, styleValues, setStyleValues, scrollToRecs }) => {

  return(
    <Box>
    {
      !specsFull ?
        <SnareSpecs updateSpecs={updateSpecs} fullyBuilt={fullyBuilt} setFullyBuilt={setFullyBuilt} scrollToRecs={scrollToRecs}/>
      :
       <DrumStyle setFullyBuilt={setFullyBuilt} updateStyleSound={updateStyleSound}
       setSpecsFull={setSpecsFull} setSpecsToSend={setSpecsToSend} styleValues={styleValues} setStyleValues={setStyleValues} scrollToRecs={scrollToRecs}/>
    }
    </Box>
  );
}

export default SnareSelector;
