import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import CarouselBottom from './CarouselBottom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './Carousel.css';

const Carousel = ({ headData, type, includeGauge, storeSelected, sizeSelected, setSizeSelected }) => {

  const [ cardPosition, setCardPosition ] = useState('thirdPosition');
  const [ screenWidth, setScreenWidth ] = useState(null);

  const moveRight = () => {
    // console.log('moving right');
    let width = window.innerWidth;
    if(width >= 1200) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('largeWidth');
        setCardPosition('fifthPosition');
      } else {
        setScreenWidth('largeWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 1200 && width >= 900) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('mediumWidth');
        setCardPosition('fifthPosition');
      } else {
        setScreenWidth('mediumWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 900 && width >= 600) {

      setScreenWidth('smallWidth');
      if(cardPosition === 'firstPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'secondPosition') {
        setCardPosition('thirdPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('fourthPosition');
      } else {
        setCardPosition('fifthPosition');
      }
    }
    else {
      setScreenWidth('xsmallWidth');
      if(cardPosition === 'firstPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'secondPosition') {
        setCardPosition('thirdPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('fourthPosition');
      } else {
        setCardPosition('fifthPosition');
      }
    }
  }

  const moveLeft = () => {
    // console.log('moving left');
    let width = window.innerWidth;
    if(width >= 1200) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('largeWidth');
        setCardPosition('firstPosition');
      } else {
        setScreenWidth('largeWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 1200 && width >= 900) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('mediumWidth');
        setCardPosition('firstPosition');
      } else {
        setScreenWidth('mediumWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 900 && width >= 600) {
      setScreenWidth('smallWidth');
      if(cardPosition === 'secondPosition') {
        setCardPosition('firstPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'fourthPosition') {
        setCardPosition('thirdPosition');
      } else {
        setCardPosition('fourthPosition');
      }

      // setScreenWidth('smallWidth');
    } else {
      setScreenWidth('xsmallWidth');
      if(cardPosition === 'secondPosition') {
        setCardPosition('firstPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'fourthPosition') {
        setCardPosition('thirdPosition');
      } else {
        setCardPosition('fourthPosition');
      }
    }
  }


  return(
    <Box sx={{ maxWidth: '1100px', padding: {md: '0'}  }}>
      {
        type === 'BASS RESO' ?
        <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '46px', sm: '80px', md: '80px', lg: '80px'}, fontSize: {xs: '130px', sm: '190px', md: '290px', lg: '360px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
          BASS
        </Typography>
        :
        (
          type === 'SNARE' ?
          <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '50px', sm: '80px', md: '70px', lg: '70px'}, fontSize: {xs: '110px', sm: '175px', md: '260px', lg: '340px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
            {type}
          </Typography>
          :
          <Typography variant="h2" component="h2" sx={{maxHeight: {lg: '290px', md: '230px', sm: '150px'}, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 5, top: {xs: '50px', sm: '80px', md: '80px', lg: '80px'}, fontSize: {xs: '125px', sm: '190px', md: '290px', lg: '370px'}, zIndex: '1', color: '#303236', fontWeight: '700', opacity: '0.4', maxWidth:'1100px', width: '100%', overflow: 'hidden'}}>
            {type}
          </Typography>
        )
      }

        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems:'center' }}>
          {
            cardPosition === 'firstPosition' ?
            <Button aria-label='no more' variant='text' sx={{zIndex: '3'}} disabled>
              <ArrowBackIosNewIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
            </Button>
            :
            <Button variant='text' aria-label='move left' sx={{zIndex: '3'}} onClick={moveLeft}>
              <ArrowBackIosNewIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
            </Button>
          }

          <Box sx={{ width: {lg: '900px', md: '875px', sm: '430px', xs: '350px'}, display: 'flex', flexFlow: 'row nowrap', marginTop: {xs: '22px', sm: '50px', md: '95px'}, marginBottom: {xs: '5px', sm: '5px'}, justifyContent: 'center' }} className={`cardContainer ${screenWidth}`}>

          { headData.map(head => {

          return (
            <Card key={head.id} sx={{ width: {xs: '225px', sm: '270px', md: '225px', lg: '270px'}, minWidth: {xs: '225px', sm: '270px', md: '225px', lg: '270px'}, background: 'none', zIndex: '2' }} className={` ${screenWidth} ${cardPosition} ${head.id === 1 ? 'firstCard' : ( head.id === 5 ? 'lastCard' : 'midCards')} `}>
              <Box sx={{ width: '95%', margin: '0 auto'}}>
              <CardMedia
                component="img"
                image={head.imageLink}
                alt={head.model}
                className='cardMedia'
                sx={{ padding: '3px 2px 3px 0', width: {xs: '195px', sm: '235px', md: '195px', lg: '235px'}, height: {xs: '195px', sm: '235px', md: '195px', lg: '235px'}, margin: '0 auto' }}
              />
              </Box>
              <CardHeader
                sx={{ color: '#eceff1', padding: {xs: '3px 12px 4px', sm: '5px 12px 8px', md: '10px 12px 2px', lg: '16px 16px 2px'} }}
                title={<Typography className='cardGradient' sx={{fontSize: {xs: '30px', sm: '30px', md: '30px'}, fontWeight: '600', color: '#2196f3', lineHeight: {xs: '33px'} }}>{head.brand}</Typography>}
                subheader={<Typography sx={{ fontSize: {md: '15px', lg: '17px'}, color: '#2196f3', fontWeight: '500' }} className='cardGradient'>{head.model}</Typography>}
              />
              <CardContent sx={{ padding: {xs: '5px 16px 7px', md: '5px 12px 10px', lg: '8px 16px 10px'} }}>
                <Typography variant="body2" className='cardLight' sx={{ fontSize: {xs: '14px', sm: '16px', md: '14px'} }}>
                  {head.spec1}
                </Typography>
                <Typography variant="body2" className='cardLight'sx={{ fontSize: {xs: '14px', sm: '16px', md: '14px'} }}>
                  {head.spec2}
                </Typography>
              </CardContent>
              <Box>
                <CarouselBottom head={head} type={type} storeSelected={storeSelected} sizeSelected={sizeSelected} setSizeSelected={setSizeSelected}/>
              </Box>
            </Card>
            )
          })
        }
        </Box>
        {
          cardPosition === 'fifthPosition' ?
          <Button variant='text' aria-label='no more' sx={{zIndex: '3', padding: {xs: '6px 8px', sm: '6px 4px', md: '6px 8px'} }} disabled>
            <ArrowForwardIosIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
          </Button>
          :
          <Button variant='text' aria-label='move right' sx={{zIndex: '3', padding: {xs: '6px 8px', sm: '6px 4px', md: '6px 8px'} }} onClick={moveRight}>
            <ArrowForwardIosIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
          </Button>
        }
      </Box>
      {
        includeGauge ?
        <Box sx={{ width: '98%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center', padding: '5px 0', margin: {xs: '3px auto 0', sm: '10px auto 5px', md: '3px auto 5px', lg:'10px auto 5px'} }}>
          <Box sx={{ border: '1px solid #ffffffb3', borderRadius: '5px', width: {xs: '75%', sm: '65%', md: '65%'}, maxWidth: '500px', margin: '0 auto', height: '8px' }}>
            <Box sx={{ width: { xs: '23%', sm: '23%', md: '52%' }, height: '8px', borderRadius: '5px' }} className={`gaugeGradient ${screenWidth} ${cardPosition}`}></Box>
          </Box>
          <Box className='guage-text' sx={{ width: {xs: '90%', sm: '75%', md: '75%'}, maxWidth: '570px', height: '30px', margin: '5px 0 0 4px', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
            <Box><Typography sx={{ fontSize:{xs: '13px', sm: '14px'}, fontWeight: '300', color: '#eceff1' }}>Slightly Darker</Typography></Box>
            <Box><Typography sx={{ fontSize:{xs: '13px', sm: '14px'}, fontWeight: '300', color: '#eceff1' }}>Medium</Typography></Box>
            <Box><Typography sx={{ fontSize:{xs: '13px', sm: '14px'}, fontWeight: '300', color: '#eceff1' }}>Slightly Brighter</Typography></Box>
          </Box>
        </Box>
        :
        <Box>
        </Box>
      }
    </Box>
  );
}

export default Carousel;
