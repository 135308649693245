import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Affiliate = () => {
  return(
    <Box className='light-font' sx={{ margin: {xs: '0 auto 40px', sm: '0 auto 50px', md: '10px auto 75px', lg: '10px auto 75px'}, width: {xs: '90%', sm: '555px', md: '850px', lg: '900px'} }}>

        <Box sx={{ margin: {xs: '50px auto 55px', sm: '50px auto 30px', md: '60px auto 40px'}, width: {xs: '300px', sm: '525px', md: '565px', lg: '645px'}, maxWidth: '96%'}}>
          <Typography variant="p" component="p" textAlign="center" sx={{ fontSize:{xs: '16px', sm: '18px', md: '18px', lg: '18px'}, margin: '0'  }}>
              * DrumheadSelector may receive a small commission for sales generated through Amazon product links.  Of course, your price doesn't increase at all!
          </Typography>
          <Typography variant="p" component="p" textAlign="center" sx={{ fontSize:{xs: '16px', sm: '18px', md: '18px', lg: '18px'}, margin: '10px 0 15px'  }}>
              Thanks so much for your support!
          </Typography>
        </Box>
    </Box>
  );
}

export default Affiliate;
