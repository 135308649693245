import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import HelpPopover from '../../popups/HelpPopover';
import { useState } from 'react';
import './Carousel.css';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: grey[400],
  backgroundColor: grey[500],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: grey[500],
    boxShadow: 'none'
  },
}));

const eightInch = '8"'
const tenInch = '10"';
const twelveInch = '12"';
const thirteenInch = '13"';
const fourteenInch = '14"';
const fifteenInch = '15"';
const sixteenInch = '16"';
const eighteenInch = '18"';
const twentyInch = '20"';
const twentyTwoInch = '22"';
const twentyFourInch = '24"';
const twentySixInch = '26"';


const CarouselBottom = ({ head, type, storeSelected, sizeSelected, setSizeSelected }) => {

  const [size, setSize] = useState('');
  const [buttonEnabled, setButtonEnabled ] = useState(false);
  const [buttonLinks, setButtonLinks] = useState(
    {
      id: '',
      buyLink: '',
      amazonBuyLink: '',
      guitarCenterBuyLink: '',
      sweetwaterBuyLink: ''
    }
  );

  const checkSizeSelected = () => {
    if (type === 'SNARE' && !sizeSelected.snare) {
      setSizeSelected({ ...sizeSelected, snare: true });
    } else if (type === 'TOMS' && !sizeSelected.toms){
      setSizeSelected({ ...sizeSelected, toms: true });
    }
    else if (type === 'BASS' && !sizeSelected.bass){
      setSizeSelected({ ...sizeSelected, bass: true });
    }
    else if (type === 'BASS RESO' && !sizeSelected.bassReso){
      setSizeSelected({ ...sizeSelected, bassReso: true });
    } else {
      return;
    }
  }

  const openBuyLink = (id) => {
    if(storeSelected === 'amazon') {
          // console.log(buttonLinks.amazonBuyLink);
          window.open(
            buttonLinks.amazonBuyLink, "_blank");
    } else if (storeSelected === 'guitar center') {
          // console.log(buttonLinks.guitarCenterBuyLink);
          window.open(
            buttonLinks.guitarCenterBuyLink, "_blank");
    } else {
          // console.log(buttonLinks.sweetwaterBuyLink);
          window.open(
            buttonLinks.sweetwaterBuyLink, "_blank");
        }
    };


  const changeLink = (id, amazonLink, gcLink, swLink) => {
    setButtonLinks( {amazonBuyLink: amazonLink, guitarCenterBuyLink: gcLink, sweetwaterBuyLink: swLink});
    // console.log('button links', buttonLinks);
  }

  const handleChange = (id, value) => {
    // console.log("value ", value);
      setSize(value);
      setButtonEnabled(true);
      checkSizeSelected();
      let match = head.sizes.indexOf(parseInt(value));
      changeLink(id, head.amazonBuyLinks[match], head.gcBuyLinks[match], head.swBuyLinks[match]);
  }


  return(
    <Box sx={{ margin: {xs: '25px auto 10px', sm: '20px auto 10px', md: '10px auto 10px' }, height: {xs: '80px', md: '55px'}, display: 'flex', flexFlow: {xs: 'column nowrap', md: 'row nowrap'}, justifyContent: 'center', alignItems: 'center' }}>

    {
      type === 'SNARE' ?
      <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
        <InputLabel id="demo-simple-select-label">Size</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={size}
          label="Size"
          onChange={(e) => handleChange(head.id, e.target.value)}
        >
          <MenuItem value={10}>{tenInch}</MenuItem>
          <MenuItem value={12}>{twelveInch}</MenuItem>
          <MenuItem value={13}>{thirteenInch}</MenuItem>
          <MenuItem value={14}>{fourteenInch}</MenuItem>
        </Select>
      </FormControl>
      :
      (
        type === 'TOMS' ?
      <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
        <InputLabel id="demo-simple-select-label">Size</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={size}
          label="Size"
          onChange={(e) => handleChange(head.id, e.target.value)}
        >
          <MenuItem value={8}>{eightInch}</MenuItem>
          <MenuItem value={10}>{tenInch}</MenuItem>
          <MenuItem value={12}>{twelveInch}</MenuItem>
          <MenuItem value={13}>{thirteenInch}</MenuItem>
          <MenuItem value={14}>{fourteenInch}</MenuItem>
          <MenuItem value={15}>{fifteenInch}</MenuItem>
          <MenuItem value={16}>{sixteenInch}</MenuItem>
          <MenuItem value={18}>{eighteenInch}</MenuItem>
        </Select>
      </FormControl>
        :
        <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
          <InputLabel id="demo-simple-select-label">Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={size}
            label="Size"
            onChange={(e) => handleChange(head.id, e.target.value)}
          >
            <MenuItem value={18}>{eighteenInch}</MenuItem>
            <MenuItem value={20}>{twentyInch}</MenuItem>
            <MenuItem value={22}>{twentyTwoInch}</MenuItem>
            <MenuItem value={24}>{twentyFourInch}</MenuItem>
            <MenuItem value={26}>{twentySixInch}</MenuItem>
          </Select>
        </FormControl>
        )
      }

      {
        !buttonEnabled  ?
        <ColorButton variant="contained" sx={{ margin: {xs: '10px 20px 15px', sm: '10px 20px 5px', md: '10px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '12px 16px 13px', sm: '12px 16px 13px', md: '11px 10px', lg: '11px 10px'}, lineHeight: {xs: '1.3em', sm: '1.3em', md: '1.3em', lg: '1.3em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'} }}>Choose Size</ColorButton>
        :
        (
          storeSelected === 'amazon' ?
          (
            buttonLinks.amazonBuyLink === 'none' ?
              <Box>
                <HelpPopover storeSelected={storeSelected} />
              </Box>
              :
              <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 15px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                <Box>See on</Box>
                <Box>Amazon</Box>
              </Button>
          )
          :
          (
            storeSelected === 'guitar center' ?
            (
             buttonLinks.guitarCenterBuyLink === 'none' ?
              <Box>
               <HelpPopover storeSelected={storeSelected} />
              </Box>
              :
              <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 15px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                <Box>See on</Box>
                <Box>Guitar Center</Box>
              </Button>
             )
              :
              buttonLinks.sweetwaterBuyLink === 'none' ?
                <Box>
                  <HelpPopover storeSelected={storeSelected} />
                </Box>
                :
                <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '13px', sm: '13px', md: '12px', lg: '12px'}, margin: {xs: '10px 20px 15px', sm: '10px 20px 5px', md: '11px 5px', lg: '10px 5px 10px 12px'}, padding: {xs: '6px 16px', sm: '6px 16px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                  <Box>See on</Box>
                  <Box>Sweetwater</Box>
                </Button>
            )
          )
      }
    </Box>
  );
}

export default CarouselBottom;
