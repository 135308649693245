export const bassData = [
  {
    id: 1,
    brand: 'Evans',
    model: 'Clear GMAD',
    spec1: '1-ply, 12-mil thickness',
    spec2: 'Interchangeable dampening',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-gmad-clear.webp',
    buyLinks: ['www.evansGmad18', 'www.evansGmad20', 'www.evansGmad22', 'www.evansGmad24', 'www.evansGmad26'],
    amazonBuyLinks: ['https://amzn.to/3sMkDE9',
                     'https://amzn.to/3LyGKVh',
                     'https://amzn.to/3G6EDXi',
                     'https://amzn.to/3yQ22Lk',
                     'https://amzn.to/386WEbG'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-18-in-1274034479216.gc',
                 'https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-20-in-1274034479626.gc',
                 'https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-22-in-1274034479378.gc',
                 'https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-24-in-1274034479135.gc',
                 'https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-26-in-1296839766933.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BD18GMAD--evans-gmad-clear-drumhead-with-damping-system-18-inch',
                 'https://www.sweetwater.com/store/detail/BD20GMAD--evans-gmad-clear-drumhead-with-dampening-system-20-inch',
                 'https://www.sweetwater.com/store/detail/BD22GMAD--evans-gmad-clear-drumhead-with-damping-system-22-inch',
                 'none',
                 'none']
  },
  {
    id: 2,
    brand: 'Evans',
    model: 'Clear EMAD',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Interchangeable dampening',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-emad-clear.webp',
    buyLinks: ['www.remoEmad18', 'www.remoEmad20', 'www.remoEmad22', 'www.remoEmad24', 'www.remoEmad26'],
    amazonBuyLinks: ['https://amzn.to/3PCAj6S',
                     'https://amzn.to/39IqJyN',
                     'https://amzn.to/3MyilAd',
                     'https://amzn.to/3LyRylW',
                     'https://amzn.to/3NodBNN'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/EMAD-Clear-Batter-Bass-Drumhead-18-in-1274034479501.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Clear-Batter-Bass-Drumhead-20-in-1274034479499.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Clear-Batter-Bass-Drumhead-22-in-1274034479870.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Clear-Batter-Bass-Drumhead-24-in-1274034479624.gc',
                 'https://www.guitarcenter.com/Evans/EMAD-Clear-Batter-Bass-Drumhead-26-in-1296839766932.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BD18EMAD--evans-emad-clear-bass-drum-batter-head-18-inch',
                 'https://www.sweetwater.com/store/detail/BD20EMAD--evans-emad-clear-bass-drum-batter-head-20-inch',
                 'https://www.sweetwater.com/store/detail/BD22EMAD--evans-emad-clear-bass-drum-batter-head-22-inch',
                 'https://www.sweetwater.com/store/detail/BD24EMAD--evans-emad-clear-bass-drum-batter-head-24-inch',
                 'none']
  },
  {
    id: 3,
    brand: 'Remo',
    model: 'Clear Powerstroke P3/Dot',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring & dot',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-p3-dot-clear.webp',
    buyLinks: ['www.remo18', 'www.remo20', 'www.remo22', 'www.remo24', 'www.remo26'],
    amazonBuyLinks: ['https://amzn.to/3NJxxuP',
                     'https://amzn.to/3G54XkM',
                     'https://amzn.to/3LzTB9z',
                     'https://amzn.to/3LydKgh',
                     'none'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Black-Dot-18-in-1441723055538.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Black-Dot-20-in-1412001824969.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Black-Dot-22-in-1382969921198.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Black-Dot-24-in-1382366866227.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Black-Dot-26-in-1441723055530.gc'],
    swBuyLinks: ['none',
                 'none',
                 'https://www.sweetwater.com/store/detail/P3132210--remo-powerstroke-p3-clear-bass-drumhead-22-inch-top-black-dot',
                 'none',
                 'none']
  },
  {
    id: 4,
    brand: 'Remo',
    model: 'Coated Powerstroke P3',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-p3-coated-bd.webp',
    buyLinks: ['www.remoPS18', 'www.remoPS20', 'www.remoPS22', 'www.remoPS24', 'www.remoPS26'],
    amazonBuyLinks: ['https://amzn.to/3wtNv6n',
                     'https://amzn.to/3GbyYzp',
                     'https://amzn.to/386H8N0',
                     'https://amzn.to/3lwoqkM',
                     'https://amzn.to/3GjbpF7'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Coated-Powerstroke-3-Bass-Drum-Head-18-in-1274034491268.gc',
                 'https://www.guitarcenter.com/Remo/Coated-Powerstroke-3-Bass-Drum-Head-20-in-1274034490667.gc',
                 'https://www.guitarcenter.com/Remo/Coated-Powerstroke-3-Bass-Drum-Head-22-in-1274034491101.gc',
                 'https://www.guitarcenter.com/Remo/Coated-Powerstroke-3-Bass-Drum-Head-24-in-1274034490887.gc',
                 'https://www.guitarcenter.com/Remo/Coated-Powerstroke-3-Bass-Drum-Head-26-in-1274034490863.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/P31118C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-18-inch-coated',
                 'https://www.sweetwater.com/store/detail/P31120C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-20-inch-coated',
                 'https://www.sweetwater.com/store/detail/P31122C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-22-inch-coated',
                 'https://www.sweetwater.com/store/detail/P31124C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-24-inch-coated',
                 'https://www.sweetwater.com/store/detail/P31126-C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-26-inch-coated']
  },
  {
    id: 5,
    brand: 'Remo',
    model: 'Clear Powerstroke P3',
    spec1: '1-ply, 10-mil thickness',
    spec2: 'Dampening ring',
    sizes: [18, 20, 22, 24, 26],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-p3-clear-bd.webp',
    buyLinks: ['www.remoPS18', 'www.remoPS20', 'www.remoPS22', 'www.remoPS24', 'www.remoPS26'],
    amazonBuyLinks: ['https://amzn.to/39C9dMr',
                     'https://amzn.to/3wLE4OK',
                     'https://amzn.to/3G3QMwd',
                     'https://amzn.to/3sNURiS',
                     'https://amzn.to/3wyWPpK'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Impact-Patch-18-in-1274034490773.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Impact-Patch-20-in-1274034490563.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Impact-Patch-22-in-1274034491187.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Impact-Patch-24-in-1274034490978.gc',
                 'https://www.guitarcenter.com/Remo/Powerstroke-3-Clear-Bass-Drum-Head-with-Impact-Patch-26-in-1274034490811.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/P31318C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-18-inch-clear',
                 'https://www.sweetwater.com/store/detail/P31320C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-20-inch-clear',
                 'https://www.sweetwater.com/store/detail/P31322-C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-22-inch-clear',
                 'https://www.sweetwater.com/store/detail/P1324C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-24-inch-clear',
                 'https://www.sweetwater.com/store/detail/P31326C2--remo-powerstroke-3-bass-drumhead-with-2.5-inch-impact-pad-26-inch-clear']
  }
]
