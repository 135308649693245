import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import StoreIcon from '@mui/icons-material/Store';
import amazonLogo from '../../assets/amazonLogo.png';
import guitarCenterLogo from '../../assets/guitarCenterLogo.png';
import sweetWaterLogo from '../../assets/sweetWaterLogo.jpeg';

const StorePopover = ({ changeStore, storeSelected, openStoreIcon, handleClose, id, open, anchorEl }) => {
  return(
    <Box sx={{ display: 'none', margin: {xs: '0 2px 0 0', sm: '0 2px 0 0', md: '0 20px 0 0', lg: '0 20px 0 0'} }}>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
        <Box>
            <Box sx={{ }}>
              <Paper>
                <Box sx={{ padding: '10px 20px', border: '1px solid #ffffffb3' }}>
                  <Typography variant='h6' component='h6' color="#eceff1" sx={{ textAlign: 'center', fontWeight: '400', fontSize: '18px' }}>Which store do you prefer?</Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: '16px', color: '#ffffffb3' }}>Links will be changed to selected option.</Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: '14px', color: '#ffffffb3' }}>(Defaults to Amazon)</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', margin: '20px auto'}}>

                    <Box sx={{ maxWidth: '83px' }} onClick={() => {changeStore('amazon'); handleClose()} }>
                      <img src={amazonLogo} alt='amazon logo' className={`popper-logo ${ storeSelected === 'amazon' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                    <Box sx={{ maxWidth: '83px'}} onClick={() => {changeStore('guitar center'); handleClose()} }>
                      <img src={guitarCenterLogo} alt='guitar center logo' className={`popper-logo ${ storeSelected === 'guitar center' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                    <Box sx={{ maxWidth: '83px'}} onClick={() => {changeStore('sweetwater'); handleClose()} }>
                      <img src={sweetWaterLogo} alt='sweetwater logo' className={`popper-logo ${ storeSelected === 'sweetwater' ? 'store-selected' : 'store-not-selected'}`} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
      </Popover>
      <IconButton
        size="large"
        aria-label="store select"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openStoreIcon}
        color="primary"
        sx={{ padding: '8px'}}
      >
        <StoreIcon  sx={{ fontSize: {xs: '28px', sm: '30px'} }}/>
      </IconButton>

      <Box sx={{ marginTop: {xs: '-7px', sm: '-5px'} }}>
        <Typography sx={{ color: '#2196f3', fontSize: {xs: '11px', sm: '12px'}, lineHeight: '1em', fontWeight: {xs: '500', sm: '500'} }}>CHANGE</Typography>
        <Typography sx={{ color: '#2196f3', fontSize: {xs: '11px', sm: '12px'}, lineHeight: '1em', fontWeight: {xs: '500', sm: '500'} }}>STORE</Typography>
      </Box>
    </Box>
  );
}

export default StorePopover;
