import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import HelpPopover from '../popups/HelpPopover';
import StoreChange from '../../components/popups/StoreChange';
import AffiliateModal from '../affiliateModal/AffiliateModal';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { bassData } from '../../assets/headData/bassData';

const eightInch = '8"'
const tenInch = '10"';
const twelveInch = '12"';
const thirteenInch = '13"';
const fourteenInch = '14"';
const fifteenInch = '15"';
const sixteenInch = '16"';
const eighteenInch = '18"';
const twentyInch = '20"';
const twentyTwoInch = '22"';
const twentyFourInch = '24"';
const twentySixInch = '26"';

const ColorButton = styled(Button)(({ theme }) => ({
  color: grey[400],
  backgroundColor: grey[500],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: grey[500],
    boxShadow: 'none'
  },
}));


const Featured = ({ storeSelected, changeStore }) => {

  // making local variable to set which head from head data to feature
  const type = 'BASS';
  const head = bassData[1];

  const [openAffiliate, setOpenAffiliate] = useState(false);
  const handleAffiliateClose = () => setOpenAffiliate(false);


// maybe change text links to these functions below?
  const handleGmadLink = () => {
    if(storeSelected === 'amazon') {
          window.open('https://amzn.to/3J5CK0F', "_blank");
    } else if (storeSelected === 'guitar center') {
          window.open('https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-22-in-1274034479378.gc', "_blank");
    } else {
          window.open('https://www.sweetwater.com/evans-gmad/series', "_blank");
        }
  }

  const handleEmad2Link = () => {
    if(storeSelected === 'amazon') {
          window.open('https://amzn.to/3XwI9m2', "_blank");
    } else if (storeSelected === 'guitar center') {
          window.open('https://www.guitarcenter.com/Evans/EMAD-2-Clear-Batter-Bass-Drumhead-22-in-1274034479807.gc', "_blank");
    } else {
          window.open('https://www.sweetwater.com/store/detail/BD22EMAD2--evans-emad2-clear-bass-batter-head-22-inch', "_blank");
        }
  }


  const [size, setSize] = useState('');
  const [buttonEnabled, setButtonEnabled ] = useState(false);
  const [sizeSelected, setSizeSelected] = useState({
    snare: false,
    toms: false,
    bassReso: false,
    bass: false
  });

  const [buttonLinks, setButtonLinks] = useState(
    {
      id: '',
      buyLink: '',
      amazonBuyLink: '',
      guitarCenterBuyLink: '',
      sweetwaterBuyLink: ''
    }
  );

  const checkSizeSelected = () => {
    if (type === 'SNARE' && !sizeSelected.snare) {
      setSizeSelected({ ...sizeSelected, snare: true });
    } else if (type === 'TOMS' && !sizeSelected.toms){
      setSizeSelected({ ...sizeSelected, toms: true });
    }
    else if (type === 'BASS' && !sizeSelected.bass){
      setSizeSelected({ ...sizeSelected, bass: true });
    }
    else if (type === 'BASS RESO' && !sizeSelected.bassReso){
      setSizeSelected({ ...sizeSelected, bassReso: true });
    } else {
      return;
    }
  }

  const openBuyLink = (id) => {
    if(storeSelected === 'amazon') {
          // console.log(buttonLinks.amazonBuyLink);
          window.open(
            buttonLinks.amazonBuyLink, "_blank");
    } else if (storeSelected === 'guitar center') {
          // console.log(buttonLinks.guitarCenterBuyLink);
          window.open(
            buttonLinks.guitarCenterBuyLink, "_blank");
    } else {
          // console.log(buttonLinks.sweetwaterBuyLink);
          window.open(
            buttonLinks.sweetwaterBuyLink, "_blank");
        }
    };


  const changeLink = (id, amazonLink, gcLink, swLink) => {
    setButtonLinks( {amazonBuyLink: amazonLink, guitarCenterBuyLink: gcLink, sweetwaterBuyLink: swLink});
    // console.log('button links', buttonLinks);
  }

  const handleChange = (id, value) => {
    // console.log("value ", value);
      console.log(id, value);
      setSize(value);
      setButtonEnabled(true);
      checkSizeSelected();
      let match = head.sizes.indexOf(parseInt(value));
      changeLink(id, head.amazonBuyLinks[match], head.gcBuyLinks[match], head.swBuyLinks[match]);
  }



  return(
    <Box sx={{  padding: {xs: '15px 0px 15px', sm: '20px 10px 20px', md: '20px 15px 20px', lg: '30px 25px 20px'}, margin: {xs: '0 auto 55px', sm: '0 auto 65px', md: '0 auto 65px', lg: '55px auto 95px'}, width: {xs: '98%', sm: '565px', md: '800px', lg: '800px'} }} >
      <Box sx={{ padding: {xs: '0 10px 0px', sm: '10px 25px 5px', md: '20px 20px 5px'}, margin: '20px auto 5px'}}>
        <Box className='light-font' sx={{ margin: {xs: '10px auto', sm: '10px auto 0px auto'}, width: {xs: '95%', sm: '520px', md: '650px', lg: '700px'}, lineHeight: '1.5em' }}>
          <Typography color="primary" variant="h3" component="h3" textAlign="left" sx={{ fontWeight: '300', fontSize: {xs: '30px', sm: '35px'} }} >
                FEATURED HEAD:
          </Typography>
          <Box className='featured-head-image' sx={{ margin: {xs: '45px auto 20px', sm: '50px auto 20px', md: '60px auto 20px'}, width: {xs: '260px', sm: '260px', md: '260px', lg: '295px'}, height: {xs: '265px', sm: '265px', md: '265px', lg: '300px'} }}>

              <img src={head.imageLink} alt={head.model} />

          </Box>
          <Box sx={{ margin: {xs: '0 auto 60px'} }}>
            <Typography color="primary" variant="h5" component="h5" sx={{ fontWeight: '300', fontSize: {xs: '27px', sm: '30px', md: '34px'} }}>
              Evans Clear EMAD
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexFlow: 'column nowrap', margin: {xs: '35px 5px 0px', sm: '35px 5px 5px', md: '35px 5px 10px'} }}>

            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'} }}>
              The Evans EMAD is a very popular and versatile bass drum head.  With a standard 10-mil thickness and innovative dampening system, it's designed to work in many situations.  That's why it's included in our list of All-Purpose heads.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'}  }}>
              This head is a common result when using our recommendation tool with standard inputs.  If you have medium bass drum specs, play with a medium style, and you prefer a medium tone, an EMAD will likely be good choice for you.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'}  }}>
              The most intriguing aspect of this head is its dampening system.  'EMAD' stands for 'Externally Mounted Adjustable Damping'.  A tray is attached to the outer edge of the head, where a foam dampening ring can be placed.  The foam provides natural-sounding control, while still allowing the drum to breath.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'}  }}>
              This head ships with two different foam rings - one standard width and one wide.  Of course, the foam can also be left out completely, giving drummers plenty of flexibility with their resonance.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'}  }}>
              EMADs work well on a wide range of drum sizes.  2-ply heads often sound choked on smaller bass drums, but not so with an EMAD.  The 10-mil thickness and interchangeable dampening makes this head a good option for more diameters than most other heads on the market.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 10px 0px', sm: '0 0 10px 10px', md: '0 0 10px 10px'}  }}>
              Drummers who tend to hit very hard might be better off with an <a className='featured-link' href={ storeSelected === 'amazon' ? 'https://amzn.to/3J5CK0F' : (storeSelected === 'guitar center' ? 'https://www.guitarcenter.com/Evans/GMAD-Clear-Batter-Bass-Drumhead-22-in-1274034479378.gc' : 'https://www.sweetwater.com/evans-gmad/series')}>Evans GMAD</a> or <a href={ storeSelected === 'amazon' ? 'https://amzn.to/3XwI9m2' : (storeSelected === 'guitar center' ? 'https://www.guitarcenter.com/Evans/EMAD-2-Clear-Batter-Bass-Drumhead-22-in-1274034479807.gc' : 'https://www.sweetwater.com/store/detail/BD22EMAD2--evans-emad2-clear-bass-batter-head-22-inch')} className='featured-link'>Evans EMAD 2</a>.  Those are thicker than the standard EMAD and will offer more protection.  However, if you generally play at a normal volume, an EMAD could work great.  It can provide a good blend of punch, low-end, and resonance control on a wide variety of drums.
            </Typography>
          </Box>
          <Box sx={{ margin: {xs: '5px 0px 35px 8px', sm: '10px 0px 25px 12px'} }}>
            <Typography onClick={() => setOpenAffiliate(true)} className='affiliate-modal-text' variant="p" component="p" textAlign="left" sx={{ fontStyle: 'italic', fontWeight: '300', fontSize: {xs: '12px', sm: '13px', md: '14px', lg: '15px'} }}>
                 *affiliate disclaimer
            </Typography>
            <AffiliateModal openAffiliate={openAffiliate} setOpenAffiliate={setOpenAffiliate} handleAffiliateClose={handleAffiliateClose} />
          </Box>
        </Box>
      </Box>
      <Typography color="primary" variant="p" component="p" sx={{ fontWeight: '400', fontSize: {xs: '17px', sm: '20px', md: '22px'} }}>
        See Evans EMAD
      </Typography>
      <Box sx={{ margin: {xs: '5px auto 10px', sm: '10px auto 10px', md: '10px auto 10px' }, display: 'flex', flexFlow: {xs: 'row nowrap', sm: 'row nowrap', md: 'row nowrap'}, justifyContent: 'center', alignItems: 'center' }}>

      {
        type === 'SNARE' ?
        <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
          <InputLabel id="demo-simple-select-label">Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={size}
            label="Size"
            onChange={(e) => handleChange(head.id, e.target.value)}
          >
            <MenuItem value={10}>{tenInch}</MenuItem>
            <MenuItem value={12}>{twelveInch}</MenuItem>
            <MenuItem value={13}>{thirteenInch}</MenuItem>
            <MenuItem value={14}>{fourteenInch}</MenuItem>
          </Select>
        </FormControl>
        :
        (
          type === 'TOMS' ?
        <FormControl sx={{ maxWidth: {xs: '110px', md: '80px'}, minWidth: {xs: '110px', md: '80px'} }} size="small">
          <InputLabel id="demo-simple-select-label">Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={size}
            label="Size"
            onChange={(e) => handleChange(head.id, e.target.value)}
          >
            <MenuItem value={8}>{eightInch}</MenuItem>
            <MenuItem value={10}>{tenInch}</MenuItem>
            <MenuItem value={12}>{twelveInch}</MenuItem>
            <MenuItem value={13}>{thirteenInch}</MenuItem>
            <MenuItem value={14}>{fourteenInch}</MenuItem>
            <MenuItem value={15}>{fifteenInch}</MenuItem>
            <MenuItem value={16}>{sixteenInch}</MenuItem>
            <MenuItem value={18}>{eighteenInch}</MenuItem>
          </Select>
        </FormControl>
          :
          <FormControl sx={{ maxWidth: {xs: '80px', md: '80px'}, minWidth: {xs: '80px', md: '80px'} }} size="small">
            <InputLabel id="demo-simple-select-label">Size</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={size}
              label="Size"
              onChange={(e) => handleChange(head.id, e.target.value)}
            >
              <MenuItem value={18}>{eighteenInch}</MenuItem>
              <MenuItem value={20}>{twentyInch}</MenuItem>
              <MenuItem value={22}>{twentyTwoInch}</MenuItem>
              <MenuItem value={24}>{twentyFourInch}</MenuItem>
              <MenuItem value={26}>{twentySixInch}</MenuItem>
            </Select>
          </FormControl>
          )
        }

        {
          !buttonEnabled  ?
          <ColorButton variant="contained" sx={{ fontSize: {xs: '12px', sm: '14px'}, margin: {xs: '10px', sm: '5px 10px 5px', md: '11px 5px 11px 10px', lg: '10px 5px 10px 15px'}, padding: {xs: '12px 16px 13px', sm: '12px 12px 13px', md: '11px 9px', lg: '11px 10px'}, lineHeight: {xs: '1.1em', sm: '1.1em', md: '1.3em', lg: '1.3em'}, width: {xs: '117px', sm: '117px', md: '117px', lg: '130px'}, height: '40px' }}>Choose Size</ColorButton>
          :
          (
            storeSelected === 'amazon' ?
            (
              buttonLinks.amazonBuyLink === 'none' ?
                <Box>
                  <HelpPopover storeSelected={storeSelected} />
                </Box>
                :
                <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '12px', sm: '12px', md: '12px', lg: '12px'}, margin: {xs: '10px', sm: '5px 10px 5px', md: '11px 5px 11px 10px', lg: '10px 5px 10px 15px'}, padding: {xs: '5px 9px 6px', sm: '5px 9px 6px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '117px', sm: '117px', md: '117px', lg: '130px'}, height: '40px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                  <Box>See on</Box>
                  <Box>Amazon</Box>
                </Button>
            )
            :
            (
              storeSelected === 'guitar center' ?
              (
               buttonLinks.guitarCenterBuyLink === 'none' ?
                <Box>
                 <HelpPopover storeSelected={storeSelected} />
                </Box>
                :
                <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '12px', sm: '12px', md: '12px', lg: '12px'}, margin: {xs: '10px', sm: '5px 10px 5px', md: '11px 5px 11px 10px', lg: '10px 5px 10px 15px'}, padding: {xs: '5px 9px 6px', sm: '5px 9px 6px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '117px', sm: '117px', md: '117px', lg: '130px'}, height: '40px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                  <Box>See on</Box>
                  <Box>Guitar Center</Box>
                </Button>
               )
                :
                buttonLinks.sweetwaterBuyLink === 'none' ?
                  <Box>
                    <HelpPopover storeSelected={storeSelected} />
                  </Box>
                  :
                  <Button variant="contained" onClick={(e) => openBuyLink(head.id)} sx={{ fontSize: {xs: '12px', sm: '12px', md: '12px', lg: '12px'}, margin: {xs: '10px', sm: '5px 10px 5px', md: '11px 5px 11px 10px', lg: '10px 5px 10px 15px'}, padding: {xs: '5px 9px 6px', sm: '5px 9px 6px', md: '5px 9px 6px', lg: '5px 10px 6px'}, lineHeight: {xs: '1.2em', sm: '1.2em', md: '1.2em', lg: '1.2em'}, width: {xs: '117px', sm: '117px', md: '117px', lg: '130px'}, height: '40px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                    <Box>See on</Box>
                    <Box>Sweetwater</Box>
                  </Button>
              )
            )
        }
        <Box sx={{ margin: {xs: '0 0 0 2px', sm: '0 0 0 14px', md: '0 0 0 7px', lg: '0 0 0 15px'} }}>

            <Box sx={{ top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'} }}>
              <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
            </Box>

        </Box>
      </Box>
    </Box>
  );

}

export default Featured;
