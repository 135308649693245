import { createTheme } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';

const primaryColor = blue[500];
const secondaryColor = red[600];

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    mode: 'dark'
  },
});

export default theme;
