import Box from '@mui/material/Box';

const ScrollTo = () => {
  return(
    <Box sx={{ height: '23px', width: '50%', margin: '0 auto' }} id='selectorRef' >
    </Box>
  );
}

export default ScrollTo;
