export const tomsData = [
  {
    id: 1,
    brand: 'Evans',
    model: 'Coated G2',
    spec1: '2-ply, 7-mil + 7-mil',
    spec2: 'No dampening/dots',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-g2-coated.webp',
    buyLinks: ['www.evansG2108', 'www.evansG210', 'www.evansG21012', 'www.evansG21013', 'www.evansG21014', 'www.evansG21015', 'www.evansG21016', 'www.evansG21018'],
    amazonBuyLinks: ['https://amzn.to/3MD7PIe',
                     'https://amzn.to/3sMDO0m',
                     'https://amzn.to/3wyGgKc',
                     'https://amzn.to/38DHR8B',
                     'https://amzn.to/3sR2KE7',
                     'https://amzn.to/3yP0lgY',
                     'https://amzn.to/3wvK6DT',
                     'https://amzn.to/3yPnsrF'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-8-in-1274034479592.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-10-in-1274034479344.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-12-in-1274034478909.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-13-in-1274034479368.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-14-in-1274034479140.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-15-in-1274034479618.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-16-in-1274034479873.gc',
                 'https://www.guitarcenter.com/Evans/G2-Coated-Batter-Drumhead-18-in-1274034479843.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/B08G2--evans-g2-coated-drumhead-8-inch',
                 'https://www.sweetwater.com/store/detail/B10G2--evans-g2-coated-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/B12G2--evans-g2-coated-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/B13G2--evans-g2-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/B14G2--evans-g2-coated-drumhead-14-inch',
                 'https://www.sweetwater.com/store/detail/B15G2--evans-g2-coated-drumhead-15-inch',
                 'https://www.sweetwater.com/store/detail/B16G2--evans-g2-coated-drumhead-16-inch',
                 'https://www.sweetwater.com/store/detail/B18G2--evans-g2-coated-drumhead-18-inch']
  },
  {
    id: 2,
    brand: 'Remo',
    model: 'Clear Emperor',
    spec1: '2-ply, 7-mil + 7-mil',
    spec2: 'No dampening/dots',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-emperor-clear.webp',
    buyLinks: ['www.remoEmp108', 'www.remoEmp10', 'www.remoEmp1012', 'www.remoEmp1013', 'www.remoEmp1014', 'www.remoEmp1015', 'www.remoEmp1016', 'www.remoEmp1018'],
    amazonBuyLinks: ['https://amzn.to/3lzjZ8M',
                     'https://amzn.to/39EMBuC',
                     'https://amzn.to/38DIgrD',
                     'https://amzn.to/3LvXlJ1',
                     'https://amzn.to/3lAc76Z',
                     'https://amzn.to/39FjebV',
                     'https://amzn.to/383Tw0d',
                     'https://amzn.to/3ltEVy9'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-8-in-1274034490950.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-10-in-1274034490732.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-12-in-1274034491365.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-13-in-1274034490994.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-14-in-1274034490548.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-15-in-1274034491226.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-16-in-1274034490856.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Emperor-Batter-Drumhead-18-in-1274034491381.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BE0308--remo-emperor-clear-drumhead-8-inch',
                 'https://www.sweetwater.com/store/detail/BE0310--remo-emperor-clear-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/BE0312--remo-emperor-clear-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/BE0313--remo-emperor-clear-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/BE0314--remo-emperor-clear-drumhead-14-inch',
                 'https://www.sweetwater.com/store/detail/BE0315--remo-emperor-clear-drumhead-15-inch',
                 'https://www.sweetwater.com/store/detail/BE0316--remo-emperor-clear-drumhead-16-inch',
                 'https://www.sweetwater.com/store/detail/BE0318--remo-emperor-clear-drumhead-18-inch']
  },
  {
    id: 3,
    brand: 'Evans',
    model: 'Coated G12',
    spec1: '1-ply, 12-mil',
    spec2: 'No dampening/dots',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-g12-coated.webp',
    buyLinks: ['www.evansG128', 'www.evansG1210', 'www.evansG1212', 'www.evansG1213', 'www.evansG1214', 'www.evansG1215', 'www.evansG1216', 'www.evansG1218'],
    amazonBuyLinks: ['https://amzn.to/3Nm2xR2',
                     'https://amzn.to/3G3Wuyc',
                     'https://amzn.to/3NlUSlQ',
                     'https://amzn.to/3yQ5wNU',
                     'https://amzn.to/3sR0uwD',
                     'https://amzn.to/39zx5QR',
                     'https://amzn.to/39BIlMC',
                     'https://amzn.to/3wv0apw'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-8-in-1392739390354.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-10-in-1366643219938.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-12-in-1364827971453.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-13-in-1364827971457.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-14-in-1392739390394.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-15-in-1392739390362.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-16-in-1364827971461.gc',
                 'https://www.guitarcenter.com/Evans/G12-Coated-White-Batter-Drumhead-18-in-1392739390350.gc'],
    swBuyLinks: ['none',
                 'none',
                 'none',
                 'https://www.sweetwater.com/store/detail/B13G12--evans-g12-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/B14G12--evans-g12-coated-drumhead-14-inch',
                 'none',
                 'none',
                 'none']
  },
  {
    id: 4,
    brand: 'Remo',
    model: 'Coated Ambassador X',
    spec1: '1-ply, 12-mil',
    spec2: 'No dampening/dots',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-ambassadorX-coated.webp',
    buyLinks: ['www.remoAmbX8', 'www.remoAmbX10', 'www.remoAmbX12', 'www.remoAmbX13', 'www.remoAmbX14', 'www.remoAmbX15', 'www.remoAmbX16', 'www.remoAmbX18'],
    amazonBuyLinks: ['https://amzn.to/3wx1doX',
                     'https://amzn.to/38DMrUk',
                     'https://amzn.to/3wCc4Nq',
                     'https://amzn.to/384l19P',
                     'https://amzn.to/382Owc3',
                     'https://amzn.to/3lqgCkN',
                     'https://amzn.to/3GbJDKr',
                     'none'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-8-in-1274034491150.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-10-in-1274034490931.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-12-in-1274034491338.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-13-in-1274034491078.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-14-in-1274034490860.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-15-in-1274034491310.gc',
                 'https://www.guitarcenter.com/Remo/Ambassador-X-Coated-Drumhead-16-in-1274034490546.gc',
                 'none'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/AX0108--remo-ambassador-by-coated-drumhead-8-inch',
                 'https://www.sweetwater.com/store/detail/AX0110--remo-ambassador-by-coated-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/AX0112--remo-ambassador-by-coated-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/AX0113--remo-ambassador-by-coated-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/AX0114--remo-ambassador-by-coated-drumhead-14-inch',
                 'none',
                 'https://www.sweetwater.com/store/detail/AX0116--remo-ambassador-by-coated-drumhead-16-inch',
                 'https://www.sweetwater.com/store/detail/AX0118--remo-ambassador-by-coated-drumhead-18-inch']
  },
  {
    id: 5,
    brand: 'Evans',
    model: 'Clear G12',
    spec1: '1-ply, 12-mil',
    spec2: 'No dampening/dots',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-g12-clear.webp',
    buyLinks: ['www.evansG128', 'www.evansG1210', 'www.evansG1212', 'www.evansG1213', 'www.evansG1214', 'www.evansG1215', 'www.evansG1216', 'www.evansG1218'],
    amazonBuyLinks: ['https://amzn.to/3G40PkV',
                     'https://amzn.to/3wuN4c0',
                     'https://amzn.to/39Cey6s',
                     'https://amzn.to/3wwHg1B',
                     'https://amzn.to/3lv3uuC',
                     'https://amzn.to/3GcfdYE',
                     'https://amzn.to/39F68uY',
                     'https://amzn.to/39Efd7o'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-8-in-1364827970217.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-10-in-1364827970221.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-12-in-1364827970209.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-13-in-1384789084329.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-14-in-1384789085589.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-15-in-1392739390382.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-16-in-1364827970213.gc',
                 'https://www.guitarcenter.com/Evans/G12-Clear-Batter-Drumhead-18-in-1388417099994.gc'],
    swBuyLinks: ['none',
                 'none',
                 'none',
                 'none',
                 'none',
                 'none',
                 'none',
                 'none']
  }
]
