export const tomsBottomData = [
  {
    id: 1,
    brand: 'Remo',
    model: 'Clear Ambassador',
    spec1: '1-ply, 10-mil thickness',
    spec2: '',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/remo-ambassador-clear.webp',
    buyLinks: ['www.remoAmb8','www.remoAmb10', 'www.remoAmb12', 'www.remoAmb13', 'www.remoCAmb14', 'www.remoAmb15', 'www.remoAmb16', 'www.remoAmb18'],
    amazonBuyLinks: ['https://amzn.to/3895yFG',
                     'https://amzn.to/39CaAe4',
                     'https://amzn.to/3NuO7OW',
                     'https://amzn.to/3NqercH',
                     'https://amzn.to/39EKLtI',
                     'https://amzn.to/3G288cW',
                     'https://amzn.to/3wxdKZJ',
                     'https://amzn.to/3G8coYn'],
    gcBuyLinks: ['https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-8-in-1274034490838.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-10-in-1274034490515.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-12-in-1274034490657.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-13-in-1274034491328.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-14-in-1274034491252.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-15-in-1274034490681.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-16-in-1274034490666.gc',
                 'https://www.guitarcenter.com/Remo/Clear-Ambassador-Head-18-in-1274034491190.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/BA0308--remo-clear-ambassador-drumhead-8-inch',
                 'https://www.sweetwater.com/store/detail/BA0310--remo-clear-ambassador-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/BA0312--remo-clear-ambassador-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/BA0313--remo-clear-ambassador-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/BA0314--remo-clear-ambassador-drumhead-14-inch',
                 'https://www.sweetwater.com/store/detail/BA0315--remo-clear-ambassador-drumhead-15-inch',
                 'https://www.sweetwater.com/store/detail/BA0316--remo-clear-ambassador-drumhead-16-inch',
                 'https://www.sweetwater.com/store/detail/BA0318-00--remo-clear-ambassador-drumhead-18-inch']
  },
  {
    id: 2,
    brand: 'Evans',
    model: 'Clear G1',
    spec1: '1-ply, 10-mil thickness',
    spec2: '',
    sizes: [8, 10, 12, 13, 14, 15, 16, 18],
    imageLink: 'https://drumheadselector.b-cdn.net/evans-g1-clear.webp',
    buyLinks: ['www.evansG18', 'www.evansG110', 'www.evansG112', 'www.evansG113', 'www.evansG114', 'www.evansG115', 'www.evansG116', 'www.evansG118'],
    amazonBuyLinks: ['https://amzn.to/3MAMS0e',
                     'https://amzn.to/3LyPf2D',
                     'https://amzn.to/3LxylkO',
                     'https://amzn.to/3wBOG2x',
                     'https://amzn.to/3MxEDSP',
                     'https://amzn.to/38DNzr2',
                     'https://amzn.to/3G7OIUg',
                     'https://amzn.to/3874FNO'],
    gcBuyLinks: ['https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-8-in-1274034479764.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-10-in-1274034479293.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-12-in-1274034479381.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-13-in-1274034479128.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-14-in-1274034479860.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-15-in-1274034479287.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-16-in-1274034479398.gc',
                 'https://www.guitarcenter.com/Evans/G1-Clear-Batter-Drumhead-18-in-1274034479794.gc'],
    swBuyLinks: ['https://www.sweetwater.com/store/detail/TT08G1--evans-g1-clear-drumhead-8-inch',
                 'https://www.sweetwater.com/store/detail/TT10G1--evans-g1-clear-drumhead-10-inch',
                 'https://www.sweetwater.com/store/detail/TT12G1--evans-g1-clear-drumhead-12-inch',
                 'https://www.sweetwater.com/store/detail/TT13G1--evans-g1-clear-drumhead-13-inch',
                 'https://www.sweetwater.com/store/detail/TT14G1--evans-g1-clear-drumhead-14-inch',
                 'https://www.sweetwater.com/store/detail/TT15G1--evans-g1-clear-drumhead-15-inch',
                 'https://www.sweetwater.com/store/detail/TT16G1--evans-g1-clear-drumhead-16-inch',
                 'https://www.sweetwater.com/store/detail/TT18G1--evans-g1-clear-drumhead-18-inch']
  }
];
