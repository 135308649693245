import DrumStyle from '../drumStyle/DrumStyle';
import BassSpecs from './BassSpecs';
import Box from '@mui/material/Box';

const BassSelector = ({ specsFull, setSpecsFull, updateSpecs, fullyBuilt, setFullyBuilt, updateStyleSound, setSpecsToSend, styleValues, setStyleValues, scrollToRecs }) => {

  return(
    <Box>
    {
      !specsFull ?
       <BassSpecs updateSpecs={updateSpecs} fullyBuilt={fullyBuilt} setFullyBuilt={setFullyBuilt} />
      :
       <DrumStyle setFullyBuilt={setFullyBuilt} updateStyleSound={updateStyleSound}
       setSpecsFull={setSpecsFull} setSpecsToSend={setSpecsToSend} styleValues={styleValues} setStyleValues={setStyleValues} />
    }
    </Box>
  );
}

export default BassSelector;
