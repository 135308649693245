import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, useEffect } from 'react';

const SnareSpecs = ({ updateSpecs, fullyBuilt, setFullyBuilt }) => {

    const [snareBuild, setSnareBuild] = useState({
      diameter: null,
      depth: null,
      shell: null,
      lugs: null,
      hoops: null,
      quality: null,
      era: null
    });

    // loop through snareBuild to see if all properies have values
    // if so, the next button is enabled
    let nullCount = 0;

    const checkSnareBuild = () => {
      for(const prop in snareBuild) {
        // console.log(snareBuild[prop]);
        if(snareBuild[prop] !== null) {
          nullCount += 1;
          if(nullCount === 7) {
            // console.log('7');
            setFullyBuilt(true);
          } else {
            // console.log('nulls: ', nullCount);
          }
        }
      }
    }

    useEffect(() => {
      // console.log('useEffect ', snareBuild);
      checkSnareBuild();
      // console.log(nullCount);
    });

    const recsRef = document.getElementById('recsRef');
    const scrollToRecs = () => {
      recsRef.scrollIntoView({ behavior: 'smooth' });
    };


    const [diameter, setDiameter] = useState('');
    const handleDiameterChange = (event) => {
      let diameterValue = event.target.value;
      setDiameter(diameterValue);
      setSnareBuild({...snareBuild, diameter: diameterValue});
    };

    const [depth, setDepth] = useState('');
    const handleDepthChange = (event) => {
      let depthValue = event.target.value;
      setDepth(depthValue);
      setSnareBuild({...snareBuild, depth: depthValue});
    };

    const [shell, setShell] = useState('');
    const handleShellChange = (event) => {
      let shellValue = event.target.value;
      setShell(shellValue);
      setSnareBuild({...snareBuild, shell: shellValue});
    };

    const [lugs, setLugs] = useState('');
    const handleLugsChange = (event) => {
      let lugsValue = event.target.value;
      setLugs(lugsValue);
      setSnareBuild({...snareBuild, lugs: lugsValue});
    };

    const [hoops, setHoops] = useState('');
    const handleHoopsChange = (event) => {
      let hoopsValue = event.target.value;
      setHoops(hoopsValue);
      setSnareBuild({...snareBuild, hoops: hoopsValue});
    };

    const [quality, setQuality] = useState('');
    const handleQualityChange = (event) => {
      let qualityValue = event.target.value;
      setQuality(qualityValue);
      setSnareBuild({...snareBuild, quality: qualityValue});
    };

    const [era, setEra] = useState('');
    const handleEraChange = (event) => {
      let eraValue = event.target.value;
      setEra(eraValue);
      setSnareBuild({...snareBuild, era: eraValue});
    };

    const thinHoop = '< 2mm';
    const mediumHoop = '2mm - 2.5mm';
    const thickHoop = '> 2.5mm';

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);


//resets all input values
    const startOver = (e) => {
      setFullyBuilt(false);
      nullCount = 0;

        setSnareBuild({
          diameter: null,
          depth: null,
          shell: null,
          lugs: null,
          hoops: null,
          quality: null,
          era: null
        });
        setDiameter('');
        setDepth('');
        setShell('');
        setLugs('');
        setHoops('');
        setQuality('');
        setEra('');
}

  return(

    <Box sx={{ height: {xs: '520px', sm: '535px', md: '450px', lg: '450px'}, maxWidth: {xs: '700px', lg: '800px'}, width: { xs: '90%', sm: '90%', md: '85%'}, padding: {xs: '3px 10px 10px 10px', sm: '10px'}, margin: '0 auto' }} >
      <Box sx={{ height: {xs: '415px', sm: '435px', md: '350px'} }}>
        <Box sx={{ flexGrow: 1, margin: {xs: '0 10px 38px', sm: '0 10px 35px'} }}>
        <Typography variant="h4" component="h4" color="#ffffffb3" sx={{ display: 'inline', fontSize: '20px', fontWeight: '500', padding: '5px'}}>
          SELECT YOUR SPECS
        </Typography>
          <HelpIcon fontSize="small" color="#ffffff" className='help-icon' onClick={handleOpen}/>
        </Box>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ }}
          >
            <Box className='modal-style' sx={{ backgroundColor: '#eceff1' }}>
              <Box sx={{  margin: {xs: '10px 0 0 7px', sm: '15px 0 0 15px'}}}>
                <Typography id="modal-modal-title" variant="h4" component="h3" sx={{ color: '#131414', fontSize: {xs: '24px', sm: '26px', md: '28px'}, fontWeight: '500'}}>
                  SNARE DRUM SPEC HELP
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3, color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  If you don't know or can't find out certain specifications, estimate with the information below.  If an educated guess is made with one or two of the specs, the result should still generate good results.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  DIAMETER
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  A drum's diameter is measured across the head, from the inside of the hoop on one side to the other.  The hoops are not included in this measurement.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  DEPTH
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  A drum's depth measures the shell, not including the hoops.  Measure to and from where the drumheads contact the shell.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  SHELL MATERIAL
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  The shell material is often made clear by the model name or it can be found with a simple Google search.  For hybrid shells, select the most prominent wood.  If you have an unknown student snare, it is likely either made of steel, basswood, or poplar.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  LUG COUNT
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  Lugs are counted per side.  Count the number of tension rods used to tighten each head.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px' }}>
                  HOOP TYPE
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  Triple-flanged hoops are installed on the vast majority of drums.  If you don't know how thick your hoops are - budget drums typically have thin flanged hoops, semi-pro drums typically have medium flanged hoops, and professional drums typically have medium flanged, thick flanged, die-cast, or wood hoops.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px'}}>
                  QUALITY LEVEL
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  As a rough estimation, budget snares sell new for under $200, semi-pro snares sell new for $200 - $400, and professional snares sell new for over $400.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ color: '#131414', fontSize: {xs: '18px', sm: '19px', md: '19px'}, fontWeight: '500', lineHeight: '22px'}}>
                  ERA
                </Typography>
                <Typography id="modal-modal-description" sx={{ mb: '20px', color: '#131414', fontSize: {xs: '14px', sm: '15px', md: '15px' } }}>
                  If you don't know the approximate date of a drum, some research may need to be done.  If you can't determine its age, make an educated guess based on drum badges, colors, etc.
                </Typography>
              </Box>
            </Box>
          </Modal>

        <Grid sx={{ justifyContent: 'center', mb: {xs: '26px', sm: '40px'} }} container columnSpacing={2} rowSpacing={{xs: '29px', sm: '32px'}}>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Diameter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={diameter}
                  label="Diameter"
                  onChange={handleDiameterChange}
                  >
                <MenuItem value={13}>13"</MenuItem>
                <MenuItem value={14}>14"</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Depth</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={depth}
                  label="Depth"
                  onChange={handleDepthChange}
                  >
                <MenuItem value={3}>3" - 3.5"</MenuItem>
                <MenuItem value={4}>4" - 4.5"</MenuItem>
                <MenuItem value={5}>5" - 5.5"</MenuItem>
                <MenuItem value={6}>6" - 6.5"</MenuItem>
                <MenuItem value={7}>7" - 7.5"</MenuItem>
                <MenuItem value={8}>8"+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Shell Material</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={shell}
                  label="Shell Material"
                  onChange={handleShellChange}
                  >
                <MenuItem value={'acrylic'}>Acrylic</MenuItem>
                <MenuItem value={'aluminum'}>Aluminum</MenuItem>
                <MenuItem value={'basswood'}>Basswood</MenuItem>
                <MenuItem value={'birch'}>Birch</MenuItem>
                <MenuItem value={'brass'}>Brass</MenuItem>
                <MenuItem value={'mahogany'}>Mahogany</MenuItem>
                <MenuItem value={'maple'}>Maple</MenuItem>
                <MenuItem value={'oak'}>Oak</MenuItem>
                <MenuItem value={'poplar'}>Poplar</MenuItem>
                <MenuItem value={'steel'}>Steel</MenuItem>
                <MenuItem value={'other metal'}>Other/Unknown Metal</MenuItem>
                <MenuItem value={'other wood'}>Other/Unknown Wood</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Lug Count</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lugs}
                  label="Lug Count"
                  onChange={handleLugsChange}
                  >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Hoop Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hoops}
                  label="Hoops Type"
                  onChange={handleHoopsChange}
                  >
                <MenuItem value={'thin'}><Typography>Thin Flanged ({thinHoop})</Typography></MenuItem>
                <MenuItem value={'medium'}>Medium Flanged ({mediumHoop})</MenuItem>
                <MenuItem value={'thick'}>Thick Flanged ({thickHoop})</MenuItem>
                <MenuItem value={'die-cast'}>Die-Cast</MenuItem>
                <MenuItem value={'wood'}>Wood</MenuItem>
                <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quality Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quality}
                  label="Quality Level"
                  onChange={handleQualityChange}
                  >
                <MenuItem value={'budget'}>Budget</MenuItem>
                <MenuItem value={'semi-pro'}>Semi-Pro</MenuItem>
                <MenuItem value={'professional'}>Professional</MenuItem>
                <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Era</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={era}
                  label="Era"
                  onChange={handleEraChange}
                  >
                <MenuItem value={'pre-1970s'}>Pre-1970s</MenuItem>
                <MenuItem value={'1970s'}>1970s</MenuItem>
                <MenuItem value={'1980s'}>1980s</MenuItem>
                <MenuItem value={'1990s'}>1990s</MenuItem>
                <MenuItem value={'2000s'}>2000s</MenuItem>
                <MenuItem value={'not-sure'}>Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {
        fullyBuilt ?
        <Button variant="contained" onClick={() => updateSpecs(snareBuild)}>
          Next
        </Button>
        :
        <Tooltip title="Fill out all specs" placement="top">
        <span>
          <Button variant="contained" disabled >
            Next
          </Button>
          </span>
        </Tooltip>
      }
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignSelf: 'center'}}>
        <Box sx={{ width: '30px', height: '30px'}}>
        </Box>
        <Box>
          <Typography id="modal-modal-description" sx={{ margin: {xs: '10px 0 12px', sm: '16px 0'} }} className="start-over" onClick={startOver}>
            start over
          </Typography>
        </Box>
        <Box sx={{ width: '30px', height: '30px'}}>
          <Box sx={{ display: {xs: 'block', md: 'block' }}} >
            <ArrowDownwardIcon fontSize="medium" className='down-arrow-icon' sx={{ alignSelf: 'start', fontSize: {xs: '24px', md: '28px', lg: '30px'} }} onClick={()=> scrollToRecs()}/>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default SnareSpecs;
