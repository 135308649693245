import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ResultsCarouselBottom from './ResultsCarouselBottom';
import { useState } from 'react';
import './resultsCarousel.css';
import remoLogo from '../../../assets/remo-logo-gray-small.png';
import evansLogo from '../../../assets/evans-logo-gray-small.png';

const ResultsCarousel = ({ headData, type, includeGauge, storeSelected }) => {

  const [ cardPosition, setCardPosition ] = useState('thirdPosition');
  const [ screenWidth, setScreenWidth ] = useState(null);

  const moveRight = () => {
    let width = window.innerWidth;
    if(width >= 1200) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('largeWidth');
        setCardPosition('fifthPosition');
      } else {
        setScreenWidth('largeWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 1200 && width >= 900) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('mediumWidth');
        setCardPosition('fifthPosition');
      } else {
        setScreenWidth('mediumWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 900 && width >= 600) {
      setScreenWidth('smallWidth');
      if(cardPosition === 'firstPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'secondPosition') {
        setCardPosition('thirdPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('fourthPosition');
      } else {
        setCardPosition('fifthPosition');
      }
    }
    else {
      setScreenWidth('xsmallWidth');
      if(cardPosition === 'firstPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'secondPosition') {
        setCardPosition('thirdPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('fourthPosition');
      } else {
        setCardPosition('fifthPosition');
      }
    }
  }

  const moveLeft = () => {
    let width = window.innerWidth;
    if(width >= 1200) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('largeWidth');
        setCardPosition('firstPosition');
      } else {
        setScreenWidth('largeWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 1200 && width >= 900) {
      if(cardPosition === 'thirdPosition') {
        setScreenWidth('mediumWidth');
        setCardPosition('firstPosition');
      } else {
        setScreenWidth('mediumWidth');
        setCardPosition('thirdPosition');
      }
    } else if (width < 900 && width >= 600) {
      setScreenWidth('smallWidth');
      if(cardPosition === 'secondPosition') {
        setCardPosition('firstPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'fourthPosition') {
        setCardPosition('thirdPosition');
      } else {
        setCardPosition('fourthPosition');
      }
    } else {
      setScreenWidth('xsmallWidth');
      if(cardPosition === 'secondPosition') {
        setCardPosition('firstPosition');
      } else if(cardPosition === 'thirdPosition') {
        setCardPosition('secondPosition');
      } else if(cardPosition === 'fourthPosition') {
        setCardPosition('thirdPosition');
      } else {
        setCardPosition('fourthPosition');
      }
    }
    // console.log('width', width);
  }
  // console.log('buttonsEnabled: ', buttonsEnabled);
  // with blue 'match' text --> <Box><Typography sx={{ fontSize:{xs: '14px', sm: '14px', md: '15px', lg: '16px'}, fontWeight: '500', color: '#2196f3' }}>Match</Typography></Box>

  return(
    <Box sx={{ maxWidth: '1100px', height: {xs: '525px', sm: '620px', md: '540px', lg: '570px'} }}>

        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems:'center', height: {xs: '460px', sm: '554px', md: 'auto', lg: 'auto'} }}>
          {
            cardPosition === 'firstPosition' ?
            <Button variant='text' aria-label='no more' sx={{zIndex: '3'}} disabled>
              <ArrowBackIosNewIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
            </Button>
            :
            <Button variant='text' aria-label='move left' sx={{zIndex: '3'}} onClick={moveLeft}>
              <ArrowBackIosNewIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
            </Button>
          }
          <Box sx={{ width: {lg: '890px', md: '875px', sm: '430px', xs: '350px'}, display: 'flex', flexFlow: 'row nowrap', marginTop: {xs: '20px', sm: '22px', md: '40px', lg: '30px'}, marginBottom: '10px', justifyContent: 'center' }} className={`resultsCardContainer ${screenWidth}`}>
          { headData.map(head => {

          return (
            <Box key={head.id}>
            {
              head.brand === 'blank-dark' || head.brand === 'blank-bright' ?
              <Card key={head.id} sx={{ width: {xs: '225px', sm: '270px', md: '225px', lg: '260px'}, minWidth: {xs: '225px', sm: '270px', md: '225px', lg: '260px'}, height: {xs: '445px', sm: '495px', md: '405px', lg: '440px'}, background: 'linear-gradient(to top, #242424, #2e2e2f)', zIndex: '2', position: 'relative' }} className={` results-cards ${screenWidth} ${cardPosition} ${head.id === 1 ? 'firstCard' : ( head.id === 5 ? 'lastCard' : 'midCards')} `}>
                <Box sx={{ padding: '12px 2px 3px 0', width: {xs: '86%', sm: '86%', md: '86%', lg: '86%'}, margin: '0 auto', height: {xs: '190px', sm: '230px', md: '190px', lg: '230px'} }}>
                  <Box sx={{ width: {xs: '99%', sm: '99%', md: '99%', lg: '99%'}, height: {xs: '190px', sm: '230px', md: '190px', lg: '230px'}, borderRadius: '100%', backgroundColor: '#303236'}}></Box>
                </Box>
                <CardHeader
                  sx={{ color: '#eceff1', padding: {xs: '5px 12px 8px', sm: '5px 12px 8px', md: '4px 12px 8px', lg: '4px 16px 6px'} }}
                  title={
                    head.brand === 'blank-bright' ?
                    <Typography sx={{fontSize: {xs: '20px', sm: '22px', md: '20px', lg: '22px'}, fontWeight: '400', color: '#ffffffb3', lineHeight: '30px', margin: '30px 0 0'}}>No Brighter Recommendations</Typography>
                    :
                    <Typography sx={{fontSize: {xs: '20px', sm: '22px', md: '20px', lg: '22px'}, fontWeight: '400', color: '#ffffffb3', lineHeight: '30px', margin: '30px 0 0'}}>No Darker Recommendations</Typography>
                  }
                />
                <CardContent sx={{ padding: {xs: '4px 12px 6px', md: '4px 12px 6px', lg: '4px 16px 8px'} }}>
                  <Box sx={{ width: '100px', height: '27px', margin: '0 auto'}}>
                  </Box>
                </CardContent>
                <Box sx={{ width: {xs: '225px', sm: '270px', md: '225px', lg: '270px'}, margin: '0 auto', display: 'flex', flexFlow: {xs: 'column nowrap', md: 'row nowrap'}, justifyContent: 'center', alignItems: {xs: 'center', md: ''}}}>
                  <Box sx={{ width: {xs: '110px', sm: '110px', md: '80px', lg: '80px'}, height: {xs: '43px', md: '40px'}, backgroundColor: '#303236', borderRadius: '5px'}}></Box>
                  <Box sx={{ width: {xs: '150px', sm: '150px', md: '117px', lg: '130px'}, height: {xs: '43px', md: '40px'}, backgroundColor: '#303236', borderRadius: '5px', margin: {xs: '10px 20px 5px', sm: '10px 20px 5px', md: '0 5px', lg: '0 5px 0 12px'}}}></Box>
                </Box>
              </Card>
              :
              <Box key={head.id} sx={{ width: {xs: '225px', sm: '270px', md: '225px', lg: '260px'}, minWidth: {xs: '225px', sm: '270px', md: '225px', lg: '260px'}, height: {xs: '435px', sm: '495px', md: '405px', lg: '440px'}, zIndex: '1', position: 'relative', borderRadius: '5px' }} className={` results-cards ${screenWidth} ${cardPosition} ${head.id === 1 ? 'firstCard' : ( head.id === 5 ? 'lastCard' : 'midCards')} `}>
                {
                  head.brand === 'Remo' ?
                  <Box sx={{ position: 'absolute', zIndex: '-1', width: '100%', padding: '30px 0 0', opacity: '0.7'}}>
                    <img src={remoLogo} alt='remo' className='resultsBackground'/>
                  </Box>
                  :
                  <Box sx={{ position: 'absolute', zIndex: '-1', width: '100%', padding: '47px 0 0', opacity: '0.7'}}>
                    <img src={evansLogo} alt='evans' className='resultsBackground'/>
                  </Box>
                }
                <Box sx={{ padding: '12px 2px 3px 0', width: {xs: '190px', sm: '225px', md: '190px', lg: '218px'}, margin: '0 auto', height: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto'}, zIndex: '3' }} >
                  <img src={head.imageLink} alt={head.model} className='resultsCardMedia' />
                </Box>
                <Box sx={{ color: '#eceff1', padding: {xs: '5px 12px 8px', sm: '5px 12px 8px', md: '4px 12px 8px', lg: '4px 16px 6px'} }} >
                  <Typography className='cardGradient' sx={{fontSize: {xs: '26px', sm: '26px', md: '26px', lg: '26px'}, fontWeight: '600', color: '#2196f3'}}>{head.brand}</Typography>
                  <Typography sx={{ fontSize: {xs: '15px', sm: '16px', md: '15px', lg: '16px'}, color: '#2196f3', fontWeight: '500' }} className='cardGradient'>{head.model}</Typography>
                </Box>
                <Box sx={{ padding: {xs: '4px 12px 6px', md: '4px 12px 6px', lg: '4px 16px 8px'} }}>
                  <Typography variant="body2" className='cardLight' sx={{ fontSize: {xs: '14px', sm: '16px', md: '13px'} }}>
                    {head.spec1}
                  </Typography>
                  <Typography variant="body2" className='cardLight'sx={{ fontSize: {xs: '14px', sm: '16px', md: '13px'} }}>
                    {head.spec2}
                  </Typography>
                </Box>
                <Box>
                  <ResultsCarouselBottom head={head} type={type} storeSelected={storeSelected} />
                </Box>
              </Box>
            }
            </Box>
            )
          })
        }
        </Box>
        {
          cardPosition === 'fifthPosition' ?
          <Button variant='text' aria-label='no more' sx={{zIndex: '3', padding: {xs: '6px 8px', sm: '6px 4px', md: '6px 8px'} }} disabled>
            <ArrowForwardIosIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
          </Button>
          :
          <Button variant='text' aria-label='move right' sx={{zIndex: '3', padding: {xs: '6px 8px', sm: '6px 4px', md: '6px 8px'} }} onClick={moveRight}>
            <ArrowForwardIosIcon sx={{ fontSize: {xs: '30px', sm: '30px', md: '35px', lg: '40px'} }}/>
          </Button>
        }
      </Box>
      {
        includeGauge ?
        <Box sx={{ width: '98%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center', padding: '5px', margin: '2px auto 0'}}>
          <Box sx={{ border: '1px solid #ffffffb3', borderRadius: '5px', width: {xs: '75%', sm: '65%', md: '65%'}, maxWidth: '500px', margin: '0 auto', height: '8px' }}>
            <Box sx={{ width: { xs: '23%', sm: '23%', md: '52%' }, height: '8px', borderRadius: '5px' }} className={`gaugeGradient ${screenWidth} ${cardPosition}`}></Box>
          </Box>
          <Box className='guage-text' sx={{ width: {xs: '73%', sm: '70%', md: '70%'}, maxWidth: '490px', height: '30px', margin:'5px 0 0 3px', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
            <Box><Typography sx={{ fontSize:'14px', fontWeight: '300', color: '#eceff1' }}>Darker</Typography></Box>
            <Box><Typography sx={{ fontSize:'14px', fontWeight: '300', color: '#eceff1' }}>Brighter</Typography></Box>
          </Box>
        </Box>
        :
        <Box>
        </Box>
      }
    </Box>
  );
}

export default ResultsCarousel;
