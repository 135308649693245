import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const About = () => {

  return(
    <Box sx={{  padding: {xs: '25px 0px 5px', sm: '30px 10px 20px', md: '40px 15px 20px', lg: '45px 25px 20px'}, margin: {xs: '0 auto', sm: '0 auto 30px', md: '0 auto 65px', lg: '0 auto 80px'}, width: {xs: '98%', sm: '565px', md: '800px', lg: '800px'}  }} >
      <Box sx={{ padding: {xs: '15px 10px', sm: '20px 25px'}, margin: '20px auto', pt: {xs: '20px', md: '20px'} }}>
        <Box className='light-font' sx={{ margin: '10px auto 25px auto', width: {xs: '95%', sm: '520px', md: '650px', lg: '700px'}, lineHeight: '1.5em' }}>
          <Typography color="primary" variant="h5" component="h5" textAlign="left" sx={{ fontWeight: '300', fontSize: {xs: '30px', sm: '35px'} }} >
                ABOUT US:
          </Typography>
          <Box sx={{ display: 'flex', flexFlow: 'column nowrap', margin: {xs: '35px 5px 5px', sm: '35px 5px'}, width: {xs: '95%', sm: '100%'} }}>

            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'} }}>
                How do you choose drumheads?  Most drummers do a Google search for things like 'best snare drum heads', 'best bass drum heads for rock', or something similar.  While that can provide good information, it's only part of the equation.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'} }}>
                Why?  Because the effectiveness of a drumhead is determined by the head, the drum it's on, and the player that's using it.
                Heads that work great on one drum might be terrible on another drum, or for another drummer, so it can be tricky to narrow down which heads are the most likely to give you the outcome you're looking for in your particular situation.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'} }}>
                DrumheadSelector.com is here to help. Our customized recommendation tool uses an exclusive algorithm, based on decades of experience, to help drummers find heads that work.
                We factor in the specs of drumheads, the specs of your specific drum, your specific playing style, and your preferred sound to come up with the best possible drumhead recommendations.
            </Typography>
            <Typography variant="p" component="p" textAlign="left" sx={{ fontSize: {xs: '16px', sm: '18px'}, margin: {xs: '0 0 25px 0px', sm: '0 0 25px 10px'} }}>
                Simply put, we want to make selecting drumheads easy, so you don't have to sift through a bunch of conflicting information that doesn't apply to you.
                Whether you're an experienced drummer or a drummer who's just starting out, we hope this site helps you save time, save money, and helps you sound great.  Thanks for visiting!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}

export default About;
