import Box from '@mui/material/Box';
import DrumType from './drumType/DrumType';
import TomsSelector from './tomsSelector/TomsSelector';
import SnareSelector from './snareSelector/SnareSelector';
import BassSelector from './bassSelector/BassSelector';
import CustomResults from '../customResults/CustomResults';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useState } from 'react';

const Selector = ({ storeSelected, changeStore }) => {

  const [drumType, setDrumType] = useState('snare');
  const [fullyBuilt, setFullyBuilt] = useState(false);
  const [specsFull, setSpecsFull] = useState(false);
  const [styleFull, setStyleFull] = useState(false);
  const [styleValues, setStyleValues] = useState({
    style: 3,
    darkBright: 3,
    dryResonant: 3
  });

  const [customShowing, setCustomShowing] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);
  const [specsToSend, setSpecsToSend] = useState({});

  //mimicking a server response
  const [responseArray, setResponseArray] = useState([{},{},{},{},{}]);

  const getRecommendations = (obj) => {
    if(drumType === 'snare') {
      let styleSound = obj;
      let snareSending = {specsToSend, styleSound};
      // console.log(snareSending);
      setShowLoading(true);
      setCustomShowing(true);
      fetch('https://dhs-app-77rpq.ondigitalocean.app/snare', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(snareSending)
      })
        .then(response => response.json())
        .then(data => {
          // console.log('response:', data);
          setResponseArray(data);
          setShowLoading(false);
      }).catch(error => setFetchingError(true));

    } else if (drumType === 'toms') {
      let styleSound = obj;
      let tomsSending = {specsToSend, styleSound};
      // console.log(tomsSending);
      setShowLoading(true);
      setCustomShowing(true);
      fetch('https://dhs-app-77rpq.ondigitalocean.app/toms', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(tomsSending)
      })
        .then(response => response.json())
        .then(data => {
          // console.log('response:', data);
          setResponseArray(data);
          setShowLoading(false);
      }).catch(error => setFetchingError(true));

    } else {
      let styleSound = obj;
      let bassSending = {specsToSend, styleSound};
      // console.log(bassSending);
      setShowLoading(true);
      setCustomShowing(true);
      fetch('https://dhs-app-77rpq.ondigitalocean.app/bass', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bassSending)
      })
        .then(response => response.json())
        .then(data => {
          // console.log('response:', data);
          setResponseArray(data);
          setShowLoading(false);
      }).catch(error => setFetchingError(true));
    }
  }

  //clicking on tab - snare, toms, bass
    const switchDrumType = (event, newValue) => {
      setDrumType(newValue);
      setSpecsFull(false);
      setFullyBuilt(false);
      setSpecsToSend({});
      setStyleValues({
        style: 3,
        darkBright: 3,
        dryResonant: 3
      });
      setShowLoading(false);
    };

  //updating the object to send to server, from drum spec window
    const updateSpecsToSend = (obj) => {
      setSpecsToSend({type: drumType, ...obj});
      setSpecsFull(true);
    }

  //updating the object to send to server, from drum style window
    const updateStyleSound = (obj) => {
      setStyleFull(true);
      // setCustomShowing(true);
      getRecommendations(obj);
      setShowLoading(true);
    }

    const startOver = (e) => {
      setSpecsFull(false);
      setFullyBuilt(false);
      setSpecsToSend({});
      setStyleValues({
          style: 3,
          darkBright: 3,
          dryResonant: 3
        });
      setShowLoading(false);
      setCustomShowing(false);
      setFetchingError(false);
      }

  return(
    <Box sx={{ height: {xs: '700px', sm: '815px', md: '705px', lg: '705px'} }} >
      {
        customShowing ?
        (
          showLoading ?
          <Box sx={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center', height: {xs: '700px', sm: '815px', md: '705px', lg: '705px'} }}>
            {
              fetchingError ?
              <Box>
                <Box sx={{ margin: '20px 0 0 0'}}>
                  <Typography sx={{ color: '#2196f3', fontSize: {xs: '18px', md: '22px'}, padding: '0 0 25px 0' }}>Sorry, there was a server error.  Please try again.</Typography>
                  <Typography sx={{ textDecoration: 'underline3'}} className='start-over start-over-error' onClick={startOver}>Start Over</Typography>
                </Box>
              </Box>
              :
              <Box sx={{ padding: {xs: '0 0 55px 0', sm: '0 0 40px 0', md: '0 0 15px 0', lg: '0 0 15px 0'} }}>
                <CircularProgress size={70}/>
                <Box sx={{ margin: '15px 0 0 0'}}>
                  <Typography sx={{ color: '#2196f3', fontSize: {xs: '18px', md: '22px'} }}>CALCULATING...</Typography>
                </Box>
              </Box>
            }

          </Box>
          :
          <CustomResults storeSelected={storeSelected} changeStore={changeStore} type={drumType} headData={responseArray} setFullyBuilt={setFullyBuilt} setSpecsFull={setSpecsFull} setSpecsToSend={setSpecsToSend} setCustomShowing={setCustomShowing} setStyleValues={setStyleValues} />
        )

        :
        <Box sx={{ padding: {lg: '10px 0 20px 0'} }}>
          <DrumType drumType={drumType} switchDrumType={switchDrumType} updateSpecs={updateSpecsToSend} />
          {
            drumType === 'snare' ?
            <SnareSelector updateSpecs={updateSpecsToSend} specsFull={specsFull} setSpecsFull={setSpecsFull} fullyBuilt={fullyBuilt} setFullyBuilt={setFullyBuilt} setSpecsToSend={setSpecsToSend} updateStyleSound={updateStyleSound}
            styleValues={styleValues} setStyleValues={setStyleValues} />
            :
            (
              drumType === 'toms' ?
              <TomsSelector updateSpecs={updateSpecsToSend} specsFull={specsFull} setSpecsFull={setSpecsFull} fullyBuilt={fullyBuilt} setFullyBuilt={setFullyBuilt} setSpecsToSend={setSpecsToSend} updateStyleSound={updateStyleSound}
              styleValues={styleValues} setStyleValues={setStyleValues} />
              :
              <BassSelector updateSpecs={updateSpecsToSend} specsFull={specsFull} setSpecsFull={setSpecsFull} fullyBuilt={fullyBuilt} setFullyBuilt={setFullyBuilt} setSpecsToSend={setSpecsToSend} updateStyleSound={updateStyleSound}
              styleValues={styleValues} setStyleValues={setStyleValues} />
            )
          }
        </Box>
      }
    </Box>
  );
}

export default Selector;
