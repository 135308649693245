import Carousel from './carousel/Carousel';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreChange from '../../components/popups/StoreChange';
import { snareData } from '../../assets/headData/snareData';
import { tomsData } from '../../assets/headData/tomsData';
import { bassData } from '../../assets/headData/bassData';
import { useState } from 'react';

const RecAccordionOuter = ({ storeSelected, changeStore } ) => {

const [expanded, setExpanded] = useState('panel1');
const [sizeSelected, setSizeSelected] = useState({
  snare: false,
  toms: false,
  bass: false,
  bassReso: false
});

const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
 };

  return(
    <Box sx={{ minHeight: '400px' }}>
      <Box sx={{ maxWidth: {lg: '1100px', md: '850px'}, margin:'0 auto'}}>
        <Accordion sx={{  minWidth: {lg: '1100px'}, position: 'relative' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1d-content"
           id="panel1a-header"
           sx={{ flexDirection: 'row-reverse', paddingLeft: {xs: '16px', sm: '35px', md: '16px'}, margin: '0 50px 0 0', width: {xs: '310px', sm: '75%', md: '85%', lg: '85%'} }}
         >
          <Typography variant="h3" component="h3" sx={{ marginLeft: {xs: '7px', sm: '15px'}, padding:'3px 0', fontSize: {xs: '16px', sm: '20px' }}}>SNARE BATTER ALL-PURPOSE</Typography>
         </AccordionSummary>
           {
             sizeSelected.snare ?
             <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
               <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
             </Box>
             :
             <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
               <StoreChange display={'hidden'}/>
             </Box>
           }
         <AccordionDetails sx={{ padding: {xs: '8px 2px 8px', sm: '8px 4px 8px', md: '8px 4px 4px', lg: '8px 16px 8px'}}}>
          <Carousel headData={snareData} type={'SNARE'} storeSelected={storeSelected} includeGauge={true} sizeSelected={sizeSelected} setSizeSelected={setSizeSelected}/>
         </AccordionDetails>
       </Accordion>

       <Accordion sx={{  minWidth: {lg: '1100px'} }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
       <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="panel1d-content"
         id="panel1a-header"
         sx={{ flexDirection: 'row-reverse', paddingLeft: {xs: '16px', sm: '35px', md: '16px'}, margin: '0 50px 0 0', width: {xs: '310px', sm: '75%', md: '85%', lg: '85%'} }}
       >
        <Typography variant="h3" component="h3" sx={{ marginLeft: {xs: '7px', sm: '15px'}, padding:'3px 0', fontSize: {xs: '16px', sm: '20px' }}}>TOMS BATTER ALL-PURPOSE</Typography>
       </AccordionSummary>
         {
           sizeSelected.toms ?
           <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
             <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
           </Box>
           :
           <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
             <StoreChange display={'hidden'}/>
           </Box>
         }
        <AccordionDetails sx={{ padding: {xs: '8px 2px 8px', sm: '8px 4px 8px', md: '8px 4px 4px', lg: '8px 16px 8px'}}}>
         <Carousel headData={tomsData} type={'TOMS'} storeSelected={storeSelected} includeGauge={true} sizeSelected={sizeSelected} setSizeSelected={setSizeSelected}/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{  minWidth: {lg: '1100px'} }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1d-content"
        id="panel1a-header"
        sx={{ flexDirection: 'row-reverse', paddingLeft: {xs: '16px', sm: '35px', md: '16px'}, margin: '0 50px 0 0', width: {xs: '310px', sm: '75%', md: '85%', lg: '85%'} }}
      >
       <Typography variant="h3" component="h3" sx={{ marginLeft: {xs: '7px', sm: '15px'}, padding:'3px 0', fontSize: {xs: '16px', sm: '20px' }}}>BASS BATTER ALL-PURPOSE</Typography>
      </AccordionSummary>
        {
          sizeSelected.bass ?
          <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
            <StoreChange display={'showing'} storeSelected={storeSelected} changeStore={changeStore}/>
          </Box>
          :
          <Box sx={{ position: 'absolute', top: {xs: '12px', sm: '14px', md: '7px', lg: '10px'}, right: {xs: '5%', sm: '9%', md: '35px', lg: '50px'} }}>
            <StoreChange display={'hidden'}/>
          </Box>
        }
       <AccordionDetails sx={{ padding: {xs: '8px 2px 8px', sm: '8px 4px 8px', md: '8px 4px 4px', lg: '8px 16px 8px'}}}>
        <Carousel headData={bassData} type={'BASS'} storeSelected={storeSelected} includeGauge={true} sizeSelected={sizeSelected} setSizeSelected={setSizeSelected}/>
       </AccordionDetails>
     </Accordion>
    </Box>
   </Box>
  );
}

export default RecAccordionOuter;
